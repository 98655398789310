import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { User } from '@/types';

export const getTechnicians = async (status: string | null = null): Promise<User[]> => {
  let url = `/admin/technician`;
  if (status) {
    url += `?status=${status}`;
  }
  return await axios.get(url);
};

type QueryFnType = typeof getTechnicians;

type UseTechniciansOptions = {
  config?: QueryConfig<QueryFnType>;
  status?: string;
};

export const useTechnicians = ({ config, status }: UseTechniciansOptions = { status: '' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['technicians'],
    queryFn: () => getTechnicians(status),
  });
};
