import React, { useEffect, useState } from 'react';
import * as z from 'zod';

import { Button } from '@/components/Elements';
import { Form, InputField } from '@/components/Form';
import { ROLES } from '@/lib/authorization';
import { technicianCreate } from '@/api/technicians/technicianCreate';
import { useNotificationStore } from '@/stores/notifications';
import { useNavigate, useParams } from 'react-router-dom';
import { createUser } from '@/api/users/createUser';
import { User } from '@/types';
import { updateUser } from '@/api/users/updateUser';
import PermissionTable from './components/PermissionTable';
import { axios } from '@/lib/axios';

type CreateAdminValues = {
  phone: string;
  name: string;
  email: string;
  password: string;
};

const schema = z.object({
  email: z.string().min(1, 'Please enter your email address'),
  phone: z
    .string()
    .min(1, 'Please enter your phone number')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only')
    .min(8, 'Please enter atleast 8 digits'),
  password: z.string().min(1, 'Please enter your password').min(8, 'Please enter atleast 8 digits'),
  name: z.string().trim().min(1, 'Please enter your name'),
});

const editSchema = z.object({
  email: z.string().min(1, 'Please enter your email address'),
  phone: z
    .string()
    .min(1, 'Please enter your phone number')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only')
    .min(8, 'Please enter atleast 8 digits'),
  name: z.string().trim().min(1, 'Please enter your name'),
});

const PermissionUser = ({ role, user }: { role: ROLES; user?: User }) => {
  const [isloading, setisloading] = useState(false);
  // ------------get permissions-------------
  const dashbeardPermission = user?.resources.find(
    (permission: any) => permission.name == 'dashboard'
  );
  const techniciansPermission = user?.resources.find(
    (permission: any) => permission.name == 'technicians'
  );
  const admin_panel_usersPermission = user?.resources.find(
    (permission: any) => permission.name == 'admin_panel_users'
  );
  const app_usersPermission = user?.resources.find(
    (permission: any) => permission.name == 'app_users'
  );
  const servicesPermission = user?.resources.find(
    (permission: any) => permission.name == 'services'
  );
  const all_ordersPermission = user?.resources.find(
    (permission: any) => permission.name == 'all_orders'
  );
  const completed_ordersPermission = user?.resources.find(
    (permission: any) => permission.name == 'completed_orders'
  );
  const deffered_ordersPermission = user?.resources.find(
    (permission: any) => permission.name == 'deffered_orders'
  );
  const add_ordersPermission = user?.resources.find(
    (permission: any) => permission.name == 'add_orders'
  );
  const invoicePermission = user?.resources.find((permission: any) => permission.name == 'invoice');
  const settingsPermission = user?.resources.find(
    (permission: any) => permission.name == 'settings'
  );
  // ---------------get permissions-------------

  const [reload, setReload] = useState(false);
  const [checked, setChecked] = useState(false);
  const [viewDashboard, setViewDashboard] = useState(
    dashbeardPermission?.permissions?.view || false
  );
  const [createDashboard, setCreateDashboard] = useState(
    dashbeardPermission?.permissions?.create || false
  );
  const [editDashboard, setEditDashboard] = useState(
    dashbeardPermission?.permissions?.edit || false
  );
  const [deleteDashboard, setDeleteDashboard] = useState(
    dashbeardPermission?.permissions?.delete || false
  );
  const [viewServices, setViewServices] = useState(servicesPermission?.permissions?.view || false);
  const [createServices, setCreateServices] = useState(
    servicesPermission?.permissions?.create || false
  );
  const [editServices, setEditServices] = useState(servicesPermission?.permissions?.edit || false);
  const [deleteServices, setDeleteServices] = useState(
    servicesPermission?.permissions?.delete || false
  );
  const [viewTechnicians, setViewTechnicians] = useState(
    techniciansPermission?.permissions?.view || false
  );
  const [createTechnicians, setCreateTechnicians] = useState(
    techniciansPermission?.permissions?.create || false
  );
  const [editTechnicians, setEditTechnicians] = useState(
    techniciansPermission?.permissions?.edit || false
  );
  const [deleteTechnicians, setDeleteTechnicians] = useState(
    techniciansPermission?.permissions?.delete || false
  );
  const [viewAdminPanelUsers, setViewAdminPanelUsers] = useState(
    admin_panel_usersPermission?.permissions?.view || false
  );
  const [createAdminPanelUsers, setCreateAdminPanelUsers] = useState(
    admin_panel_usersPermission?.permissions?.create || false
  );
  const [editAdminPanelUsers, setEditAdminPanelUsers] = useState(
    admin_panel_usersPermission?.permissions?.edit || false
  );
  const [deleteAdminPanelUsers, setDeleteAdminPanelUsers] = useState(
    admin_panel_usersPermission?.permissions?.delete || false
  );
  const [viewAppUsers, setViewAppUsers] = useState(app_usersPermission?.permissions?.view || false);
  const [createAppUsers, setCreateAppUsers] = useState(
    app_usersPermission?.permissions?.create || false
  );
  const [editAppUsers, setEditAppUsers] = useState(app_usersPermission?.permissions?.edit || false);
  const [deleteAppUsers, setDeleteAppUsers] = useState(
    app_usersPermission?.permissions?.delete || false
  );
  const [viewAllOrders, setViewAllOrders] = useState(
    all_ordersPermission?.permissions?.view || false
  );
  const [createAllOrders, setCreateAllOrders] = useState(
    all_ordersPermission?.permissions?.create || false
  );
  const [editAllOrders, setEditAllOrders] = useState(
    all_ordersPermission?.permissions?.edit || false
  );
  const [deleteAllOrders, setDeleteAllOrders] = useState(
    all_ordersPermission?.permissions?.delete || false
  );
  const [viewCompletedOrders, setViewCompletedOrders] = useState(
    completed_ordersPermission?.permissions?.view || false
  );
  const [createCompletedOrders, setCreateCompletedOrders] = useState(
    completed_ordersPermission?.permissions?.create || false
  );
  const [editCompletedOrders, setEditCompletedOrders] = useState(
    completed_ordersPermission?.permissions?.edit || false
  );
  const [deleteCompletedOrders, setDeleteCompletedOrders] = useState(
    completed_ordersPermission?.permissions?.delete || false
  );
  const [viewDefferedOrders, setViewDefferedOrders] = useState(
    deffered_ordersPermission?.permissions?.view || false
  );
  const [createDefferedOrders, setCreateDefferedOrders] = useState(
    deffered_ordersPermission?.permissions?.create || false
  );
  const [editDefferedOrders, setEditDefferedOrders] = useState(
    deffered_ordersPermission?.permissions?.edit || false
  );
  const [deleteDefferedOrders, setDeleteDefferedOrders] = useState(
    deffered_ordersPermission?.permissions?.delete || false
  );
  const [viewCancelledOrders, setViewCancelledOrders] = useState(
    deffered_ordersPermission?.permissions?.view || false
  );
  const [createCancelledOrders, setCreateCancelledOrders] = useState(
    deffered_ordersPermission?.permissions?.create || false
  );
  const [editCancelledOrders, setEditCancelledOrders] = useState(
    deffered_ordersPermission?.permissions?.edit || false
  );
  const [deleteCancelledOrders, setDeleteCancelledOrders] = useState(
    deffered_ordersPermission?.permissions?.delete || false
  );
  const [viewInprocessOrders, setViewInprocessOrders] = useState(
    deffered_ordersPermission?.permissions?.view || false
  );
  const [createInprocessOrders, setCreateInprocessOrders] = useState(
    deffered_ordersPermission?.permissions?.create || false
  );
  const [editInprocessOrders, setEditInprocessOrders] = useState(
    deffered_ordersPermission?.permissions?.edit || false
  );
  const [deleteInprocessOrders, setDeleteInprocessOrders] = useState(
    deffered_ordersPermission?.permissions?.delete || false
  );
  const [viewAddOrders, setViewAddOrders] = useState(
    add_ordersPermission?.permissions?.view || false
  );
  const [createAddOrders, setCreateAddOrders] = useState(
    add_ordersPermission?.permissions?.create || false
  );
  const [editAddOrders, setEditAddOrders] = useState(
    add_ordersPermission?.permissions?.edit || false
  );
  const [deleteAddOrders, setDeleteAddOrders] = useState(
    add_ordersPermission?.permissions?.delete || false
  );
  const [viewInvoice, setViewInvoice] = useState(invoicePermission?.permissions?.view || false);
  const [createInvoice, setCreateInvoice] = useState(
    invoicePermission?.permissions?.create || false
  );
  const [editInvoice, setEditInvoice] = useState(invoicePermission?.permissions?.edit || false);
  const [deleteInvoice, setDeleteInvoice] = useState(
    invoicePermission?.permissions?.delete || false
  );
  const [viewSettings, setViewSettings] = useState(settingsPermission?.permissions?.view || false);
  const [createSettings, setCreateSettings] = useState(
    settingsPermission?.permissions?.create || false
  );
  const [editSettings, setEditSettings] = useState(settingsPermission?.permissions?.edit || false);
  const [deleteSettings, setDeleteSettings] = useState(
    settingsPermission?.permissions?.delete || false
  );

  const data = {
    id: user?._id,
    resources: [
      {
        name: 'dashboard',
        permissions: {
          view: viewDashboard,
          create: createDashboard,
          edit: editDashboard,
          delete: deleteDashboard,
        },
      },
      {
        name: 'services',
        permissions: {
          view: viewServices,
          create: createServices,
          edit: editServices,
          delete: deleteServices,
        },
      },
      {
        name: 'technicians',
        permissions: {
          view: viewTechnicians,
          create: createTechnicians,
          edit: editTechnicians,
          delete: deleteTechnicians,
        },
      },
      {
        name: 'admin_panel_users',
        permissions: {
          view: viewAdminPanelUsers,
          create: createAdminPanelUsers,
          edit: editAdminPanelUsers,
          delete: deleteAdminPanelUsers,
        },
      },
      {
        name: 'app_users',
        permissions: {
          view: viewAppUsers,
          create: createAppUsers,
          edit: editAppUsers,
          delete: deleteAppUsers,
        },
      },
      {
        name: 'all_orders',
        permissions: {
          view: viewAllOrders,
          create: createAllOrders,
          edit: editAllOrders,
          delete: deleteAllOrders,
        },
      },
      {
        name: 'completed_orders',
        permissions: {
          view: viewCompletedOrders,
          create: createCompletedOrders,
          edit: editCompletedOrders,
          delete: deleteCompletedOrders,
        },
      },
      {
        name: 'deffered_orders',
        permissions: {
          view: viewDefferedOrders,
          create: createDefferedOrders,
          edit: editDefferedOrders,
          delete: deleteDefferedOrders,
        },
      },
      {
        name: 'cancelled_orders',
        permissions: {
          view: viewCancelledOrders,
          create: createCancelledOrders,
          edit: editCancelledOrders,
          delete: deleteCancelledOrders,
        },
      },
      {
        name: 'inprocess_orders',
        permissions: {
          view: viewInprocessOrders,
          create: createInprocessOrders,
          edit: editInprocessOrders,
          delete: deleteInprocessOrders,
        },
      },
      {
        name: 'add_orders',
        permissions: {
          view: viewAddOrders,
          create: createAddOrders,
          edit: editAddOrders,
          delete: deleteAddOrders,
        },
      },
      {
        name: 'invoice',
        permissions: {
          view: viewInvoice,
          create: createInvoice,
          edit: editInvoice,
          delete: deleteInvoice,
        },
      },
      {
        name: 'settings',
        permissions: {
          view: viewSettings,
          create: createSettings,
          edit: editSettings,
          delete: deleteSettings,
        },
      },
    ],
  };

  const submitPermission = async () => {
    setisloading(true);
    axios.post('/admin/add-permissions', data).then(() => {
      setisloading(false);
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: 'Permissions Added Successfully!',
      });
      location.reload();
    });
  };

  const navigate = useNavigate();

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setReload(true);
    setTimeout(() => setReload(false), 0);
  }, [user]);
  const formSchema = user ? editSchema : schema;

  return (
    <div className="mt-3">
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5,2fr)' }} className="form-check">
        <div></div>
        <div>
          <label
            className="form-check-label"
            style={{ fontWeight: '700' }}
            htmlFor="flexCheckDefault"
          >
            View
          </label>
        </div>
        <div>
          <label
            className="form-check-label"
            style={{ fontWeight: '700' }}
            htmlFor="flexCheckDefault"
          >
            Create
          </label>
        </div>
        <div>
          <label
            className="form-check-label"
            style={{ fontWeight: '700' }}
            htmlFor="flexCheckDefault"
          >
            Edit
          </label>
        </div>
        <div>
          <label
            className="form-check-label"
            style={{ fontWeight: '700' }}
            htmlFor="flexCheckDefault"
          >
            Delete
          </label>
        </div>
      </div>
      <br></br>
      <div>
        <PermissionTable
          name="Dashboard"
          view={viewDashboard}
          create={createDashboard}
          edit={editDashboard}
          deleteChk={deleteDashboard}
          setView={setViewDashboard}
          setCreate={setCreateDashboard}
          setEdit={setEditDashboard}
          setDelete={setDeleteDashboard}
        />
        <br></br>
        <PermissionTable
          name="Services"
          view={viewServices}
          create={createServices}
          edit={editServices}
          deleteChk={deleteServices}
          setView={setViewServices}
          setCreate={setCreateServices}
          setEdit={setEditServices}
          setDelete={setDeleteServices}
        />
        <br></br>
        <PermissionTable
          name="Technicians"
          view={viewTechnicians}
          create={createTechnicians}
          edit={editTechnicians}
          deleteChk={deleteTechnicians}
          setView={setViewTechnicians}
          setCreate={setCreateTechnicians}
          setEdit={setEditTechnicians}
          setDelete={setDeleteTechnicians}
        />
        <br></br>
        <PermissionTable
          name="Admin Panel Users"
          view={viewAdminPanelUsers}
          create={createAdminPanelUsers}
          edit={editAdminPanelUsers}
          deleteChk={deleteAdminPanelUsers}
          setView={setViewAdminPanelUsers}
          setCreate={setCreateAdminPanelUsers}
          setEdit={setEditAdminPanelUsers}
          setDelete={setDeleteAdminPanelUsers}
        />
        <br></br>
        <PermissionTable
          name="App Users"
          view={viewAppUsers}
          create={createAppUsers}
          edit={editAppUsers}
          deleteChk={deleteAppUsers}
          setView={setViewAppUsers}
          setCreate={setCreateAppUsers}
          setEdit={setEditAppUsers}
          setDelete={setDeleteAppUsers}
        />
        <br></br>
        <PermissionTable
          name="All Orders"
          view={viewAllOrders}
          create={createAllOrders}
          edit={editAllOrders}
          deleteChk={deleteAllOrders}
          setView={setViewAllOrders}
          setCreate={setCreateAllOrders}
          setEdit={setEditAllOrders}
          setDelete={setDeleteAllOrders}
        />
        <br></br>
        <PermissionTable
          name="Completed Orders"
          view={viewCompletedOrders}
          create={createCompletedOrders}
          edit={editCompletedOrders}
          deleteChk={deleteCompletedOrders}
          setView={setViewCompletedOrders}
          setCreate={setCreateCompletedOrders}
          setEdit={setEditCompletedOrders}
          setDelete={setDeleteCompletedOrders}
        />
        <br></br>
        <PermissionTable
          name="Deffered Orders"
          view={viewDefferedOrders}
          create={createDefferedOrders}
          edit={editDefferedOrders}
          deleteChk={deleteDefferedOrders}
          setView={setViewDefferedOrders}
          setCreate={setCreateDefferedOrders}
          setEdit={setEditDefferedOrders}
          setDelete={setDeleteDefferedOrders}
        />
        <br></br>
        <PermissionTable
          name="Cancelled Orders"
          view={viewCancelledOrders}
          create={createCancelledOrders}
          edit={editCancelledOrders}
          deleteChk={deleteCancelledOrders}
          setView={setViewCancelledOrders}
          setCreate={setCreateCancelledOrders}
          setEdit={setEditCancelledOrders}
          setDelete={setDeleteCancelledOrders}
        />
        <br></br>
        <PermissionTable
          name="Inprocess Orders"
          view={viewInprocessOrders}
          create={createInprocessOrders}
          edit={editInprocessOrders}
          deleteChk={deleteInprocessOrders}
          setView={setViewInprocessOrders}
          setCreate={setCreateInprocessOrders}
          setEdit={setEditInprocessOrders}
          setDelete={setDeleteInprocessOrders}
        />
        <br></br>
        <PermissionTable
          name="Add Orders"
          view={viewAddOrders}
          create={createAddOrders}
          edit={editAddOrders}
          deleteChk={deleteAddOrders}
          setView={setViewAddOrders}
          setCreate={setCreateAddOrders}
          setEdit={setEditAddOrders}
          setDelete={setDeleteAddOrders}
        />
        <br></br>
        <PermissionTable
          name="Invoice"
          view={viewInvoice}
          create={createInvoice}
          edit={editInvoice}
          deleteChk={deleteInvoice}
          setView={setViewInvoice}
          setCreate={setCreateInvoice}
          setEdit={setEditInvoice}
          setDelete={setDeleteInvoice}
        />
        <br></br>
        <PermissionTable
          name="Settings"
          view={viewSettings}
          create={createSettings}
          edit={editSettings}
          deleteChk={deleteSettings}
          setView={setViewSettings}
          setCreate={setCreateSettings}
          setEdit={setEditSettings}
          setDelete={setDeleteSettings}
        />
      </div>
      <div>
        <div className="row mt-4">
          <div className="col-4">
            <Button isLoading={isloading} type="button" onClick={() => submitPermission()} className="">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PermissionUser;
