import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

export type PdfResponse = {
  pdf: string;
};

export const getInvoicePdf = async (id: string): Promise<PdfResponse> => {
  return axios.get(`/admin/order/pdfurl/${id}`);
};
 
type QueryFnType = typeof getInvoicePdf;

type UseInvoiceOptions = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const useInvoicePdf = ({ config, id }: UseInvoiceOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['invoicePdf'],
    queryFn: () => getInvoicePdf(id),
  });
};
