import React, { useEffect, useState } from 'react';

import { ContentLayout } from '@/components/Layout';
import CreateUser from '@/container/CreateUser';
import { ROLES } from '@/lib/authorization';
import { useNavigate } from 'react-router-dom';
import { useTechnician } from '@/api/technicians/getTechnician';
import { Form, InputField, SelectField } from '@/components/Form';
import * as z from 'zod';
import { Setting } from '@/types/setting';
import { Button } from '@/components/Elements';
import { data } from 'msw/lib/types/context';
import { useTechnicians } from '@/api/technicians/getTechnicians';
import { useAppUsers } from '@/api/users/getAppUsers';
import { addBalance } from '@/api/balance/balance';
import { SearchSelect } from '@/components/Form/SerachSelect';

type CreateBalance = {
  type: string;
  userType: string;
  userId: string;
  amount: string;
  reason: string;
};

const schema = z.object({
  type: z.string().min(1, 'Please select type'),

  userType: z.string().min(1, 'Please select user type'),
  userId: z.string().min(1, 'Please select userId'),
  amount: z
    .string()
    .min(1, 'Please enter amount ')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only'),
  reason: z.string().min(1, 'Please add reason'),
});

export const Balance = () => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeLabel, setChangeLabel] = useState('');

  const { data: user } = useTechnicians();
  const { data: appUser } = useAppUsers();

  const technicianOptions =
    user?.map((entry) => ({
      label: entry.uniqueId,
      role: entry.role,
      value: entry._id,
    })) ?? [];

  const customerOptions =
    appUser?.map((entry) => ({
      label: entry.uniqueId,
      role: entry.role,
      value: entry._id,
    })) ?? [];

  const options = [
    {
      label: 'Addition',
      value: 'addition',
    },
    {
      label: 'Subtraction',
      value: 'subtraction',
    },
  ];
  const userType = [
    {
      label: 'Customer',
      value: 'customer',
    },
    {
      label: 'Technician',
      value: 'technician',
    },
  ];

  const navigate = useNavigate();

  const handleSubmit = async (values: CreateBalance) => {
    try {

      setLoading(true);
      let msg = '';

      const payload: any = {
        type: values.type,
        userType: values.userType,
        userId: values.userId,
        amount: parseInt(values.amount),
        reason: values.reason,
      };
      await addBalance(payload);
      msg = 'Balance added successfully!';

      setLoading(false);
      navigate('/app/balance-management');
    } finally {
      setLoading(false);
    }
  };

  return (
    <ContentLayout heading="" title="Admin Balance">
      <h2 className="f-20 bold pt-4">Add Balance</h2>
      <br></br>
      <p className="f-20">Please enter the following details</p>
      <div className="col-4">
        {!reload && (
          <Form<CreateBalance, typeof schema> onSubmit={handleSubmit} schema={schema}>
            {({ register, formState }) => (
              <>
                <div className="row mt-12">
                  <div className="col-12">
                    <label className="block text-sm font-medium text-gray-700">Type</label>
                    <SelectField
                      error={formState.errors['type']}
                      registration={register('type', {
                        value:''
                      })}
                      options={options}
                    />
                  </div>
                </div>

                <div className="row mt-12">
                  <div className="col-12">
                    <label className="block text-sm font-medium text-gray-700">User Type</label>
                    <SelectField
                      error={formState.errors['userType']}
                      registration={register('userType', {
                        value: '',
                        onChange: (e) => setChangeLabel(e.target.value ? e.target.value : ''),
                      })}
                      options={userType}
                    />
                  </div>
                </div>

                {changeLabel === 'customer' ? (
                  <div className="row mt-12">
                    <div className="col-12">
                      <label className="block text-sm font-medium text-gray-700">Customers</label>
                      <SearchSelect
                        error={formState.errors['userId']}
                        registration={register('userId', {
                          value:''
                        })}
                        options={customerOptions}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row mt-12">
                    <div className="col-12">
                      <label className="block text-sm font-medium text-gray-700">Technicians</label>
                      <SearchSelect
                        error={formState.errors['userId']}
                        registration={register('userId', {
                          value:''
                        })}
                        options={technicianOptions}
                      />
                    </div>
                  </div>
                )}
                <div className="row mt-4">
                  <div className="col-12">
                    <InputField
                      error={formState.errors['amount']}
                      registration={register('amount', {})}
                      type="text"
                      placeholder="Amount"
                    />
                  </div>
                </div>
                <div className="row mt-10">
                  <div className="col-18">
                    <InputField
                      error={formState.errors['reason']}
                      registration={register('reason', {})}
                      type="text"
                      placeholder="Reason"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-4">
                    <Button isLoading={loading} type="submit" className="">
                      Add Balance
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </div>
    </ContentLayout>
  );
};
