import React, { Ref, useEffect, useState } from 'react';

import { ContentLayout } from '@/components/Layout';
import { useInvoices } from '@/api/invoice/getInvoices';

import { Button, ConfirmationDialog, Spinner, Table, TableActions } from '@/components/Elements';
import { Referrals, Invoice, Service, Financial, Order } from '@/types';
import { useDisclosure } from '@/hooks/useDisclosure';
import { useNotificationStore } from '@/stores/notifications';
import { useNavigate } from 'react-router-dom';
import '../service.css';
import BackdropLoader from '@/components/Elements/Loader/Backdroploader';
import ListSkeleton from '@/components/Elements/Loader/ListSkeleton';
import { useReferrals } from '@/api/referrals/getReferrals';
import moment from 'moment';
import { upperFirst } from 'lodash';
import { getFinancialReport, useFinancialReport } from '@/api/financial/getFinancialReport';
import { getInvoicePdf } from '@/api/orders/getInvoicePdf';
import { getFinancialReportPdf } from '@/api/orders/getFinancialReportPdf';
import { getOrderByDate, useOrderByDate } from '@/api/financial/getOrderByDate';
import { useSetting } from '@/api/settings/useSetting';

export const ListFinancialReport = () => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useFinancialReport();
  const [modData, setModData] = useState<any>();
  const [filteredData, setFilteredData] = useState(data?.financialData);
  const [fromAmountSearch, setFromAmountSearch] = useState('');
  const [toAmountSearch, setToAmountSearch] = useState('');
  const [printing, setPrinting] = useState<boolean>(false);
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const settings = useSetting();

  useEffect(() => {
    if (data?.financialData && data?.financialData?.length > 0) {
      setFilteredData(data?.financialData);
    }
  }, [data?.financialData]);

  const { open: openDelete, close: closeDelete, isOpen: isDeleteOpen } = useDisclosure();

  const handleSearch = (search: any) => {
    if (search.length > 0) {
      const filtered = data?.financialData?.filter(
        (item: any) =>
          item?.orderNo.toString().includes(search) ||
          moment(item?.createdAt)
            .format('DD-MM-YYYY, hh:mm A')
            .includes(search.toLowerCase().trim()) ||
          item?.customerId?.toLowerCase().includes(search.toLowerCase()) ||
          item?.technicianId?.toLowerCase().includes(search.toLowerCase()) ||
          item?.paymentMethod?.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data?.financialData);
    }
  };
  const handleDateSearch = async () => {
    const order = await getFinancialReport(start, end);
    if (order) {
      setFilteredData(order);
    }
    if (start === '' && end === '') {
      setFilteredData(data?.financialData);
    } else {
      setFilteredData(data);
    }
  };

  const handlePrint = async (entry: Financial) => {
    setPrinting(true);
    const { pdf } = await getFinancialReportPdf(entry?.orderId);
    setPrinting(false);
    window.open(pdf, '_blank');
  };
  if (isLoading) {
    return (
      <ContentLayout title="Customer Referral Code">
        <ListSkeleton />;
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title="Customer Referral Code">
      <BackdropLoader open={printing} />

      <div
        style={{
          height: '80px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <h2 className="f-20 bold">Financial Report</h2>
        </div>
        <div className="top-form-wrap" style={{ marginRight: '0%' }}>
          <div
            className="top-search-form"
            style={{ backgroundColor: '#fff', borderRadius: '33px', paddingLeft: '8px' }}
          >
            <select>
              <option>Order no.</option>
              <option>Date</option>
              <option>Customer Id</option>
              <option>Technician Id</option>
              <option>City</option>
            </select>
            <input
              className="search-text"
              placeholder="Search..."
              type="text"
              onChange={(event) => {
                handleSearch(event.target.value);
              }}
            />
            <i className="fa fa-search"></i>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-12">
          <div>
            <div className="row search-form-banner">
              <div className="col-4 col top-search-form">
                <p>From Date</p>
                <input
                  className="search-text"
                  placeholder="Date From"
                  type="date"
                  onChange={(e) => setStart(e.target.value)}
                />
              </div>
              <div className="col-4  col top-search-form">
                <p>To Date</p>
                <div style={{ display: 'flex' }}>
                  <input
                    className="search-text"
                    placeholder="Date To"
                    type="date"
                    onChange={(e) => setEnd(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3 col top-search-form">
                <Button size="md" onClick={handleDateSearch}>
                  Check
                </Button>
              </div>
            </div>
            <div className="vb mt-4">
              <div className="row">
                <div className="col-2 top-search-form">
                  <p>Total Vat</p>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span>{data?.totalVat}</span> <span style={{ marginLeft: '4px' }}>SAR</span>
                  </div>
                </div>
                <div className="col-3 top-search-form">
                  <p>Total Technician</p>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span>{data?.totalTechnicianInterest}</span>{' '}
                    <span style={{ marginLeft: '4px' }}>SAR</span>
                  </div>
                </div>
                <div className="col-3 top-search-form">
                  <p>Total Khebra</p>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <span>{data?.totalKhebraInterest}</span>{' '}
                    <span style={{ marginLeft: '4px' }}>SAR</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12">
          <div className="side-box">
            <h2 className="f-20 bold">Important Notice</h2>
            <br></br>
            <div className="vb">
              <ul>
                <li>
                  <div className="row">
                    <div className="col-md-6">
                      <p>Vat</p>
                    </div>
                    <div className="col-md-6">{settings?.data?.vat}%</div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-md-6">
                      <p>Khebra</p>
                    </div>
                    <div className="col-md-6">{settings?.data?.khebraInterest}%</div>
                  </div>
                </li>

                <li>
                  <div className="row">
                    <div className="col-md-6">
                      <p>Technician</p>
                    </div>
                    <div className="col-md-6">{100-(settings?.data?.khebraInterest)}%</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <br></br>

      {isLoading && (
        <div className="flex items-center justify-center h-full w-full">
          <Spinner size="xl" />
        </div>
      )}

      {!isLoading && filteredData?.length > 0 ? (
        <div className="mt-4">
          <Table<Financial>
            data={filteredData ?? []}
            actions={[TableActions.PRINT]}
            tableName="technicians"
            viewTitle="View Financial Details"
            columns={[
              {
                title: 'Order No.',
                field: 'orderNo',
              },
              {
                title: 'Order Date/Time',
                field: 'createdAt',
                Cell({ entry: { createdAt } }) {
                  return <span>{moment(createdAt).format('DD-MM-YYYY, hh:mm A')}</span>;
                },
              },
              {
                title: 'Payment Method',
                field: 'orderNo',
                Cell({ entry: { orderNo } }) {
                  return <span>{'Cash'}</span>;
                },
              },
              {
                title: 'Technician Id',
                field: 'technicianId',
              },
              {
                title: 'Customer Id',
                field: 'customerId',
              },
              {
                title: 'Customer Balance Used',
                field: 'customerBalanceUsed',
              },
              {
                title: 'Coupon Amount',
                field: 'couponAmount',
              },
              {
                title: 'Vat Total',
                field: 'vatTotal',
              },
              {
                title: 'Khebra Interest',
                field: 'khebraInterest',
              },
              {
                title: 'Technician Interest',
                field: 'technicianInterest',
              },
            ]}
            onPrintClicked={handlePrint}
          />
        </div>
      ) : (
        <div className="mt-4">
          <Table<Financial>
            data={[]}
            tableName="technicians"
            viewTitle="View Financial Details"
            columns={[
              {
                title: 'No Data Found',
                field: 'orderNo',
              },
            ]}
          />
        </div>
      )}
    </ContentLayout>
  );
};
