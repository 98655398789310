import { title } from 'process';

import { ArchiveIcon } from '@heroicons/react/outline';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { User, Service, Coupon } from '@/types';
import './table.css';
import { InputField } from '@/components/Form';

export enum TableActions {
  EDIT = 'Edit',
  VIEW = 'view',
  DELETE = 'delete',
  SUSPEND = 'suspend',
  PRINT = 'print',
  PERMISSION = 'permission',
  APPROVE = 'approved',
  APPROVESERVICE = 'approved',
  SUSPENDSERVICE = 'suspend',
  ACTIVECOUPON = 'approved',
  INACTIVECOUPON = 'suspend',
  GETTENSAR = 'getTenSAR',
  SUSPENDACCOUNT = 'suspendAccount',
  EDITPRICE = 'editPrice',
}
export type TableColumn<Entry> = {
  title: string;
  field: keyof Entry;
  Cell?({ entry }: { entry: Entry }): React.ReactElement;
};

export type TableProps<Entry> = {
  data: Entry[];
  columns: TableColumn<Entry>[];
  tableName?: string;
  actions?: TableActions[];
  onSearch?: string;
  onEditClicked?: (entry: Entry) => void;
  onEditPriceClicked?: (entry: Entry) => void;
  onDeleteClicked?: (entry: Entry) => void;
  onViewClicked?: (entry: Entry) => void;
  onPrintClicked?: (entry: Entry) => void;
  onPermissionClicked?: (entry: Entry) => void;
  onSuspendClicked?: (entry: Entry, status: boolean) => void;
  onApproveClicked?: (entry: Entry, status: boolean) => void;
  onSuspendServiceClicked?: (entry: Entry, status: boolean) => void;
  onApproveServiceClicked?: (entry: Entry, status: boolean) => void;
  onInactiveCouponClicked?: (entry: Entry, status: boolean) => void;
  onActiveCouponClicked?: (entry: Entry, status: boolean) => void;
  onGetTenSARClicked?: (entry: Entry, status: boolean) => void;
  onSuspendAccountClicked?: (entry: Entry, status: boolean) => void;
  handleSearch?: (entry: Entry) => void;
  viewTitle?: string;
  ApproveTitle?: string;
  SuspendTitle?: string;
};

export const Table = <Entry extends { _id: string; sr?: number }>({
  data,
  columns,
  tableName,
  actions = [],
  onEditClicked,
  onViewClicked,
  onPrintClicked,
  onDeleteClicked,
  onApproveClicked,
  onPermissionClicked,
  onSuspendClicked,
  onApproveServiceClicked,
  onSuspendServiceClicked,
  onInactiveCouponClicked,
  onActiveCouponClicked,
  onGetTenSARClicked,
  onSuspendAccountClicked,
  onEditPriceClicked,
  handleSearch,
  viewTitle,
  ApproveTitle,
  SuspendTitle,
}: TableProps<Entry>) => {
  const [indexedEntries, setIndexedEntries] = useState<Entry[]>([]);
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [entries, setEntries] = useState<Entry[]>([]);
  const [paginationArray, setPaginationArray] = useState([1]);

  useEffect(() => {
    const indexed = data.map((i, index) => {
      i.sr = ++index;
      return i;
    });
    setIndexedEntries(indexed);
    setEntries(indexed.slice(0, pageSize));
  }, [data]);

  useEffect(() => {
    const numOfPage = Math.ceil(data.length / pageSize);

    let i = page;
    const arr = [];
    if (numOfPage > 1) {
      for (i = 1; i <= numOfPage; i++) {
        if (i > page - 3 && i < page + 3) {
          arr.push(i);
        }
      }
      setPaginationArray(arr);
    } else {
      setPaginationArray([1]);
    }
  }, [data, page]);

  const goBack = () => {
    if (page > 1) {
      const start = (page - 2) * pageSize;
      const end = (page - 1) * pageSize;
      setEntries(indexedEntries.slice(start, end));
      setPage(page - 1);
    }
  };
  
  const goForward = () => {
    const start = page * pageSize;
    const end = (page + 1) * pageSize;
    if (data.length > start) {
      setEntries(indexedEntries.slice(start, end));
      setPage(page + 1);
    }
  };

  const goToPage = (p: number) => {
    const start = (p - 1) * pageSize;
    const end = p * pageSize;
    if (data.length > start) {
      setEntries(indexedEntries.slice(start, end));
      setPage(p);
    }
  };

  const getPaginationString = () => {
    let start, end;
    if (page === 1) {
      start = page;
      end = page * pageSize;
    } else {
      start = (page - 1) * pageSize;
      end = page * pageSize;
    }
    if (end > data.length) {
      end = data.length;
    }
    return `Showing ${start} to ${end} of ${data.length} entries`;
  };

  // if (!indexedEntries?.length) {
  //   return (
  //     <div className="flex flex-col items-center justify-center text-gray-500 bg-white h-80">
  //       <ArchiveIcon className="w-16 h-16" />
  //       <h4>No Entries Found</h4>
  //     </div>
  //   );
  // }

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            {/* <div>
              <input
                className="search-text"
                placeholder="Search..."
                type="text"
                onChange={(event) => {
                  handleSearch(event.target.value);
                }}
              />
            </div> */}
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-white-50">
                <tr>
                  <th
                    scope="col"
                    className="px-3 py-3 bg-white text-sm font-medium tracking-wider text-left text-gray-700"
                  >
                    <p className="mb-0 table-title bold">Sr. No.</p>
                  </th>
                  {columns.map((column, index) => (
                    <th
                      key={column.title + index}
                      scope="col"
                      className="px-3 py-3 bg-white text-sm font-medium tracking-wider text-left text-gray-700 "
                    >
                      <p className="mb-0 table-title bold"> {column.title} </p>
                    </th>
                  ))}
                  {actions.length > 0 && (
                    <th
                      scope="col"
                      className="px-3 bold py-3 bg-white text-sm font-medium tracking-wider text-left text-gray-700"
                    >
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {entries.map((entry, entryIndex) => (
                  <tr key={entry?._id || entryIndex} className="odd:bg-white">
                    <td className="px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap">
                      {entry?.sr ?? 1}
                    </td>
                    {columns.map(({ Cell, field, title }, columnIndex) => (
                      <td
                        key={title + columnIndex}
                        className="px-6 py-3 f-14 text-gray-500 whitespace-nowrap"
                      >
                        {Cell ? <Cell entry={entry} /> : entry[field]}
                      </td>
                    ))}

                    {actions.length > 0 && (
                      <td
                        key="EDIT"
                        className="px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap"
                      >
                        <div className="d-flex cursor-pointer">
                          {actions.map((action: TableActions, index) => (
                            <span key={index}>
                              {action === TableActions.EDIT && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onEditClicked?.(entry)}
                                  onClick={() => {
                                    onEditClicked?.(entry);
                                  }}
                                  className="icon me-2"
                                  title="Edit"
                                >
                                  <i className="fa-solid fa-pen" />
                                </span>
                              )}
                              {action === TableActions.VIEW && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onViewClicked?.(entry)}
                                  onClick={() => onViewClicked?.(entry)}
                                  className="icon me-2"
                                  title={viewTitle ?? 'View'}
                                >
                                  <i className="fa-regular fa-eye" />
                                </span>
                              )}
                              {action === TableActions.PRINT && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onPrintClicked?.(entry)}
                                  onClick={() => onPrintClicked?.(entry)}
                                  className="icon me-2"
                                  title="Print Invoice"
                                >
                                  <i className="fa-solid fa-print"></i>
                                </span>
                              )}
                              {action === TableActions.PERMISSION && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onPermissionClicked?.(entry)}
                                  onClick={() => onPermissionClicked?.(entry)}
                                  className="icon me-2"
                                  title="Permission"
                                >
                                  <i className="fa-solid fa-key"></i>
                                </span>
                              )}
                              {action === TableActions.DELETE && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onDeleteClicked?.(entry)}
                                  onClick={() => onDeleteClicked?.(entry)}
                                  className="icon me-2"
                                  title="Delete"
                                >
                                  <i className="fa-solid fa-trash" />
                                </span>
                              )}
                              {action === TableActions.SUSPEND &&
                                tableName === 'technicians' &&
                                ['active'].includes((entry as unknown as User).status) && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onSuspendClicked?.(entry, false)}
                                    onClick={() => onSuspendClicked?.(entry, false)}
                                    className="icon me-2"
                                    title={SuspendTitle ?? 'De-activate'}
                                  >
                                    <i className="fa-solid fa-ban" />
                                  </span>
                                )}

                              {action === TableActions.SUSPENDSERVICE &&
                                tableName === 'services' &&
                                [false].includes((entry as unknown as Service).isDeleted) && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onSuspendServiceClicked?.(entry, false)}
                                    onClick={() => onSuspendServiceClicked?.(entry, false)}
                                    className="icon me-2"
                                    title={SuspendTitle}
                                  >
                                    <i className="fa-solid fa-ban" />
                                  </span>
                                )}
                              {action === TableActions.EDITPRICE && tableName === 'services' && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onEditPriceClicked?.(entry)}
                                  onClick={() => {
                                    onEditPriceClicked?.(entry);
                                  }}
                                  className="icon me-2"
                                  title="Sub-services"
                                >
                                  <i className="fa-solid fa-dollar-sign" />
                                </span>
                              )}
                              {action === TableActions.APPROVESERVICE &&
                                tableName === 'services' &&
                                [true].includes((entry as unknown as Service).isDeleted) && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onApproveServiceClicked?.(entry, false)}
                                    onClick={() => onApproveServiceClicked?.(entry, false)}
                                    className="icon me-2"
                                    title={ApproveTitle}
                                  >
                                    <i className="fa fa-check" />
                                  </span>
                                )}

                              {action === TableActions.INACTIVECOUPON &&
                                tableName === 'coupon' &&
                                ['active'].includes((entry as unknown as Coupon).status) && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onInactiveCouponClicked?.(entry, false)}
                                    onClick={() => onInactiveCouponClicked?.(entry, false)}
                                    className="icon me-2"
                                    title={SuspendTitle}
                                  >
                                    <i className="fa-solid fa-ban" />
                                  </span>
                                )}
                              {action === TableActions.ACTIVECOUPON &&
                                tableName === 'coupon' &&
                                ['inactive'].includes((entry as unknown as Coupon).status) && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onActiveCouponClicked?.(entry, false)}
                                    onClick={() => onActiveCouponClicked?.(entry, false)}
                                    className="icon me-2"
                                    title={ApproveTitle}
                                  >
                                    <i className="fa fa-check" />
                                  </span>
                                )}

                              {action === TableActions.APPROVE &&
                                tableName === 'technicians' &&
                                ['inactive'].includes((entry as unknown as User).status) && (
                                  <span
                                    role="button"
                                    tabIndex={-1}
                                    onKeyDown={() => onApproveClicked?.(entry, false)}
                                    onClick={() => onApproveClicked?.(entry, false)}
                                    className="icon me-2"
                                    title={ApproveTitle ?? 'Activate'}
                                  >
                                    <i className="fa fa-check" />
                                  </span>
                                )}

                              {action === TableActions.GETTENSAR && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onGetTenSARClicked?.(entry, false)}
                                  onClick={() => onGetTenSARClicked?.(entry, false)}
                                  className="border-2 border-yellow-600 p-1 rounded-md !text-yellow-600"
                                  title="Permission"
                                  style={{ color: '#FF6F00', borderColor: '#FF6F00' }}
                                >
                                  GET 10 SAR
                                </span>
                              )}

                              {action === TableActions.SUSPENDACCOUNT && (
                                <span
                                  role="button"
                                  tabIndex={-1}
                                  onKeyDown={() => onSuspendAccountClicked?.(entry)}
                                  onClick={() => onSuspendAccountClicked?.(entry)}
                                  className="border-2 border-yellow-600 p-1 rounded-md !text-yellow-600"
                                  title="Permission"
                                  style={{ color: '#FF6F00', borderColor: '#FF6F00' }}
                                >
                                  Suspend Account For 1 Day
                                </span>
                              )}
                            </span>
                          ))}
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mt-3 ms-4">{getPaginationString()}</p>
          <div className="d-flex justify-content-end my-3 me-3 pe-2">
            <div className="page me-2" onClick={goBack}>
              <span>
                <i className="fa-solid fa-chevron-left" />
              </span>
            </div>
            {paginationArray.map((i) => (
              <div key={i}>
                {(data.length >= i * pageSize || i === 1 || i === page) && (
                  <div
                    onClick={() => goToPage(i)}
                    className={clsx('page number me-2', i === page && 'active')}
                  >
                    <span>{i}</span>
                  </div>
                )}
              </div>
            ))}

            <div className="page me-2" onClick={goForward}>
              <span>
                <i className="fa-solid fa-chevron-right" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
