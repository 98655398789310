import * as React from 'react';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Controller, useForm } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number;
};

type InputFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  registration: any; // Update this type as per your needs
};

export function SearchSelect(props: InputFieldProps) {
  const { registration, error, options, label } = props;
  const { control } = useForm();
  const { onChange: originalFormChange, name } = registration;

  const handleChange = (change: (value: string | number) => void) => (
    event: React.SyntheticEvent,
    value: Option | null
  ) => {
    const selectedValue = value ? value.value : null;
    const e = {
      target: {
        value: selectedValue,
        name,
      },
    };
    originalFormChange?.(e);
    change(selectedValue);
  };

  return (
    <FieldWrapper error={error}>
      <Controller
        name={name ?? ''}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option?.label.toString()}
            value={options.find((option) => option.value === value) || null}
            onChange={handleChange(onChange)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                error={!!error?.message}
              />
            )}
          />
        )}
      />
    </FieldWrapper>
  );
}

