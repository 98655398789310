import React from 'react';

import { Button, Dialog } from '@/components/Elements';
import { Order } from '@/types';
import { formatDate } from '@/utils/format';
import { getInvoicePdf } from '@/api/orders/getInvoicePdf';
import { Entry } from 'type-fest';

export const ViewOrder = ({
  isOpen,
  close,
  handlePrint,
  order,
  title,
}: {
  title?: string;
  isOpen: boolean;
  close: () => void;
  handlePrint: (entry: Order) => void;
  order?: Order;
}) => {
  const cancelButtonRef = React.useRef(null);

  return (
    <Dialog size="large" isOpen={isOpen} onClose={close} initialFocus={cancelButtonRef}>
      <div className="inline-block align-top bg-white rounded-lg px-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
        <div className="row">
          <div style={{ marginBottom: '2%' }} className="col-12">
            {title ?? (
              <h5 style={{ textAlign: 'center' }}>
                <b>Order Details</b>
              </h5>
            )}
          </div>
          <h5>
            <b>Order</b>
          </h5>
          <div className="view-field">
            <p>Order Id:</p>
            <p>{order?.uniqueId}</p>
          </div>
          <div className="view-field">
            <p>Order Status</p>
            <p>{order?.status}</p>
          </div>
          <div className="view-field">
            <p>Service Name:</p>
            <p>{order?.serviceName}</p>
          </div>
          <div className="view-field">
            <p>Date/Time:</p>
            <p>{formatDate(order?.createdAtTime ?? '')}</p>
          </div>
          <div className="view-field">
            <p>PostPone:</p>
            <p>{order?.postpone}</p>
          </div>
          <h5>
            <b>Customer</b>
          </h5>
          <div className="view-field">
            <p>Customer Name:</p>
            <p>{order?.customer ? order.customer?.name : 'NA'}</p>
          </div>
          <div className="view-field">
            <p>Customer Id:</p>
            <p>{order?.customer?._id}</p>
          </div>
          <div className="view-field">
            <p>Customer Email:</p>
            <p>{order?.customer?.email}</p>
          </div>
          <div className="view-field">
            <p>Customer Mobile Number:</p>
            <p>{order?.customer?.phone}</p>
          </div>
          <div className="view-field">
            <p>Customer Balance:</p>
            <p>{order?.customer?.balance}</p>
          </div>
          <div className="view-field">
            <p>Customer Address:</p>
            <p>{order?.address}</p>
          </div>
          <h5>
            <b>Technician</b>
          </h5>
          <div className="view-field">
            <p>Technician Name:</p>
            <p>{order?.assignedTo ? order?.assignedTo?.name : 'NA'}</p>
          </div>
          <div className="view-field">
            <p>Technician Id:</p>
            <p>{order?.assignedTo ? order?.assignedTo?._id : 'NA'}</p>
          </div>
          <div className="view-field">
            <p>Technician Mobile Number:</p>
            <p>{order?.assignedTo ? order?.assignedTo?.phone : 'NA'}</p>
          </div>
          <div className="view-field">
            <p>Technician Email:</p>
            <p>{order?.assignedTo ? order?.assignedTo?.email : 'NA'}</p>
          </div>
          <div className="view-field">
            <p>Technician Address:</p>
            <p>{order?.assignedTo ? order?.assignedTo?.address : 'NA'}</p>
          </div>
          <h5>
            <b>Payment</b>
          </h5>
          <div className="view-field">
            <p>Customer Name :</p>
            <p>{order?.invoice ? order?.invoice?.customer_name : ''}</p>
          </div>
          <div className="view-field">
            <p>Customer Address:</p>
            <p>{order?.invoice ? order?.invoice?.customer_address : ''}</p>
          </div>
          <div className="view-field">
            <p>Customer Vat No:</p>
            <p>{order?.invoice ? order?.invoice?.customer_vat_no : 0}</p>
          </div>
          <div className="view-field">
            <p>User:</p>
            <p>{order?.invoice ? order?.invoice?.user : ''}</p>
          </div>
          {/* <h5>
            <b>Item</b>
          </h5>
          <div className="view-field">
            <p>Item Name:</p>
            <p>{order?.invoice ? order?.invoice?.item[0]?.item_name : ''}</p>
          </div>
          <div className="view-field">
            <p>Description:</p>
            <p>{order?.invoice ? order?.invoice?.item[0]?.description : ''}</p>
          </div>
          <div className="view-field">
            <p>Quantity:</p>
            <p>{order?.invoice ? order?.invoice?.item[0]?.quantity : 0}</p>
          </div>
          <div className="view-field">
            <p>Unit Price:</p>
            <p>{order?.invoice ? order?.invoice?.item[0]?.unit_price : 0}</p>
          </div>
          <div className="view-field">
            <p>Total Price:</p>
            <p>{order?.invoice ? order?.invoice?.totalPrice : 0}</p>
          </div> */}
          <h5>
            <b>Review</b>
          </h5>
          <div>
            <>
              <div className="view-field">
                <p>Rating:</p>
                <p>{order?.customer?.reviews ? order?.customer?.reviews[0]?.rating : ''}</p>
              </div>
              <div className="view-field">
                <p>Comment:</p>
                <p>{order?.customer?.reviews ? order?.customer?.reviews[0]?.comment : ''}</p>
              </div>
              <div className="view-field">
                <p>Arrival Time:</p>
                <p>{order?.customer?.reviews ? order?.customer?.reviews[0]?.arrivalTime : ''}</p>
              </div>
              <div className="view-field">
                <p>Performance Speed:</p>
                <p>
                  {order?.customer?.reviews ? order?.customer?.reviews[0]?.performanceSpeed : ''}
                </p>
              </div>
              <div className="view-field">
                <p>Work Perfection:</p>
                <p>{order?.customer?.reviews ? order?.customer?.reviews[0]?.workPerfection : ''}</p>
              </div>
              <div className="view-field">
                <p>Behaviour Manner:</p>
                <p>{order?.customer?.reviews ? order?.customer?.reviews[0]?.behaviorManner : ''}</p>
              </div>
              <div className="view-field">
                <p>Apparance:</p>
                <p>{order?.customer?.reviews ? order?.customer?.reviews[0]?.appearance : ''}</p>
              </div>
            </>
          </div>
          <div className="col-12"></div>
        </div>

        <div className="mt-2 sm:mt-4 sm:flex sm:flex-row-reverse">
          <Button type="button" onClick={close} ref={cancelButtonRef}>
            Cancel
          </Button>
          <Button
            type="button"
            // onKeyDown={() => handlePrint?.(entry)}
            onClick={() => {
              if (order) {
                handlePrint(order);
              }
            }}
            ref={cancelButtonRef}
            className="me-2"
          >
            Print
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
