import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { User } from '@/types';

export const getAppUsers = (): Promise<User[]> => {
  return axios.get(`/admin/appUser`);
};

type QueryFnType = typeof getAppUsers;

type UseAppUsersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useAppUsers = ({ config }: UseAppUsersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['app-users'],
    queryFn: () => getAppUsers(),
  });
};
