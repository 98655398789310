import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Button, Table, TableColumn } from '@/components/Elements';
import { Form, InputField, SelectField, TextAreaField } from '@/components/Form';
import { useNotificationStore } from '@/stores/notifications';
import { Invoice, User } from '@/types';
import { createInvoice } from '@/api/invoice/createInvoice';
import { updateInvoice } from '@/api/invoice/updateInvoice';
import { useTechnicians } from '@/api/technicians/getTechnicians';
import { Controller } from 'react-hook-form';
import CreateItem, { ItemValues } from '@/features/invoice/routes/CreateItem';
import { values } from 'lodash';

type CreateInvoiceValues = {
  customer_name: string;
  customer_address: string;
  user: string;
  customer_vat_no: string;
  item: ItemValues[];
  totalPrice: string;
  vat_amount: string;
  final_price: string;
  invoiceNumber: string;
};

const schema = z.object({
  customer_name: z.string().trim().min(1, 'Please enter customer name'),
  customer_address: z.string().trim().min(1, 'Please select customer address'),
  customer_vat_no: z
    .string()
    .min(1, 'Please enter customer vat number')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only'),
  user: z.string().min(1, 'Please select user'),
});

export type Edit = {
  flag: boolean;
  item: ItemValues | null;
  index: number;
};

const CreateNewInvoice = ({ invoice }: { invoice?: Invoice }) => {
  const [items, setItems] = useState<ItemValues[]>([]);
  const [edit, setEdit] = useState<Edit>({
    flag: false,
    item: null,
    index: 1,
  });
  const submitButton = useRef<HTMLButtonElement | null>(null);

  const submitItem = (values: ItemValues) => {
    setIsError(false);
    items.push(values);
    setItems(items);
    setReloading(true);
    setTimeout(() => setReloading(false), 0);
  };

  const deleteItem = (id: string) => {
    const newItems = items.filter((item) => item._id != id);
    setItems(newItems);
  };

  const updateItem = (values: ItemValues, i: number) => {
    setEdit({ flag: true, item: values, index: i });
  };

  const updateValue = (values: ItemValues) => {
    let arr = [...items];
    arr[edit.index] = values;
    setEdit({ flag: false, item: null, index: 1 });
    setItems(arr);
  };

  const [reload, setReload] = useState(false);
  const [reloding, setReloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { data: user } = useTechnicians();

  const technicianOptions =
    user?.map((entry) => ({
      label: entry.email,
      value: entry._id,
    })) ?? [];

  const navigate = useNavigate();

  useEffect(() => {
    if (invoice?.item) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [invoice]);

  if (invoice?.item) {
    useEffect(() => {
      setItems(invoice?.item);
    }, [invoice?.item]);
  }

  const handleSubmit = async (values: CreateInvoiceValues) => {
    try {
      if (items.length < 1) {
        setIsError(true);
        return;
      }
      setLoading(true);

      let msg = '';
      // const userName = user?.find((i) => i._id === values.user)?._id ?? '';

      const payload = {
        customer_name: values.customer_name,
        customer_address: values.customer_address,
        customer_vat_no: parseInt(values.customer_vat_no),
        user: values.user,
        item: items,
        totalPrice: parseInt(values.totalPrice),
        vat_amount: parseInt(values.vat_amount),
        final_price: parseInt(values.final_price),
        invoiceNumber: values.invoiceNumber,
      };

      if (invoice) {
        await updateInvoice(invoice._id, payload);
        msg = 'Invoice updated successfully!';
      } else {
        await createInvoice(payload);
        msg = 'Invoice added successfully!';
      }
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: msg,
      });
      setLoading(false);
      navigate('/app/invoice');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <div className="mt-3">
        <p className="mt-0">Please enter customer information</p>
        {!reload && (
          <>
            <Form<CreateInvoiceValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
              {({ register, formState }) => (
                <div>
                  <div>
                    <div className="row mt-4">
                      <div className="col-4">
                        <InputField
                          error={formState.errors['customer_name']}
                          registration={register('customer_name', {
                            value: invoice?.customer_name?.toString(),
                          })}
                          type="text"
                          placeholder="Customer Name"
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-4">
                        <InputField
                          error={formState.errors['customer_address']}
                          registration={register('customer_address', {
                            value: invoice?.customer_address?.toString(),
                          })}
                          type="text"
                          placeholder="Customer Address"
                        />
                      </div>
                    </div>

                    <div className="row mt-4">
                      <div className="col-4">
                        <InputField
                          error={formState.errors['customer_vat_no']}
                          registration={register('customer_vat_no', {
                            value: invoice?.customer_vat_no.toString(),
                          })}
                          type="text"
                          placeholder="Customer Vat Number"
                        />
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-4">
                        <label className="block text-sm font-medium text-gray-700">
                          Technician
                        </label>
                        <SelectField
                          error={formState.errors['user']}
                          registration={register('user', {
                            value: invoice?.user?.toString(),
                          })}
                          options={technicianOptions}
                        />
                      </div>
                    </div>
                  </div>

                  <button className="d-none" type="submit" ref={submitButton}></button>
                </div>
              )}
            </Form>
          </>
        )}
        <br></br>
        <h5 className="mt-0">Please enter items information</h5>
        {!reloding && (
          <>
            {items.length > 0 && (
              <div>
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-white-50">
                    <div>
                      <div className="row my-3 align-items-end">
                        <div className="col-2 mt-2">
                          <th>Item</th>
                        </div>
                        <div className="col-2 mt-2">
                          <th>Description</th>
                        </div>
                        <div className="col-2 mt-2">
                          <th>Quantity</th>
                        </div>
                        <div className="col-2 mt-2">
                          <th>Unit Price</th>
                        </div>
                        <div className="col-2 mt-2">
                          <th>Total</th>
                        </div>
                        <div className="col-1 mt-2">
                          <th>Update</th>
                        </div>
                      </div>
                    </div>
                  </thead>
                  <tbody>
                    {items.map((values, index) => {
                      return (
                        <tr key={values?._id}>
                          <div>
                            <div>
                              <div className="row my-3 align-items-end">
                                <div className="col-2 mt-2">
                                  <td>{values?.item_name}</td>
                                </div>
                                <div className="col-2 mt-2">
                                  <td>{values?.description}</td>
                                </div>
                                <div className="col-2 mt-2">
                                  <td>{values?.quantity}</td>
                                </div>
                                <div className="col-2 mt-2">
                                  <td>{values?.unit_price}</td>
                                </div>
                                <div className="col-2 mt-2">
                                  <td>
                                    {values?.quantity && values?.unit_price
                                      ? Number(values?.unit_price) * Number(values?.quantity)
                                      : 0}
                                  </td>
                                </div>
                                {values && (
                                  <div className="col-2 mt-1 ">
                                    <div className="mt-0 sm:mt-0 sm:flex sm:flex-row-reverse">
                                      <button
                                        style={{ marginLeft: '8px' }}
                                        className="border-btn edit"
                                        type="button"
                                        onClick={() => deleteItem(values?._id)}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        className="border-btn edit"
                                        type="button"
                                        onClick={() => updateItem(values, index)}
                                      >
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
        <div className="create-table-invoice">
          <div className="mt-3">
            <CreateItem type="add" edit={edit} submitItem={submitItem} updateValue={updateValue} />
          </div>
        </div>
        {isError && <p className="text-danger">Please select atleast one item</p>}
        <div className="row mt-4 mb-5">
          <div className="col-4">
            <Button
              onClick={() => {
                submitButton?.current?.click();
              }}
              isLoading={loading}
              className=""
            >
              {invoice ? 'Update Invoice' : 'Add Invoice'}
            </Button>
          </div>
        </div>
        {/* </>
        )} */}
      </div>
    </div>
  );
};

export default CreateNewInvoice;
