import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { Spinner } from '@/components/Elements';
import { MainLayout } from '@/components/Layout';
import { lazyImport } from '@/utils/lazyImport';
import { PermissionAdminUser } from '@/features/users/routes/admin/PermissionAdminUser';
import CreateNewInvoice from '@/container/CreateNewInvoice';
import { Balance } from '@/features/balance/AddBalance';
import { CreateCouponAdmin } from '@/features/coupons/CreateCoupon';
import { EditCoupon } from '@/features/coupons/EditCoupon';
import { CancelledOrders } from '@/features/orders/routes/CancelledOrders';
import { InprocessOrders } from '@/features/orders/routes/InProcessOrders';
import { ListFinancialReport } from '@/features/financialReport';
import { EditSubService } from '@/features/services/routes/EditSubService';
import { AddSubService } from '@/features/services/routes/AddSubService';

const { Dashboard } = lazyImport(() => import('@/features/misc'), 'Dashboard');
const { Profile } = lazyImport(() => import('@/features/users'), 'Profile');
const { AdminUsers } = lazyImport(() => import('@/features/users'), 'AdminUsers');
const { CreateAdminUser } = lazyImport(() => import('@/features/users'), 'CreateAdminUser');
const { EditAdminUser } = lazyImport(() => import('@/features/users'), 'EditAdminUser');
const { CreateUser } = lazyImport(() => import('@/features/users'), 'CreateUser');
const { EditUser } = lazyImport(() => import('@/features/users'), 'EditUser');
const { Users } = lazyImport(() => import('@/features/users'), 'Users');
const { ListServices } = lazyImport(() => import('@/features/services'), 'ListServices');
const { ListInvoice } = lazyImport(() => import('@/features/invoice'), 'ListInvoice');
const { ListCoupon } = lazyImport(() => import('@/features/coupons'), 'ListCoupon');
const { ListBalance } = lazyImport(() => import('@/features/balance'), 'ListBalance');
const { ListWorkControl } = lazyImport(() => import('@/features/workControl'), 'ListWorkControl');
const { ListSubServices } = lazyImport(() => import('@/features/services/routes/SubServices'), 'ListSubServices');
const { ListCustomerRefCode } = lazyImport(
  () => import('@/features/customerReferralCode'),
  'ListCustomerRefCode'
);
const { ListCustomerWallet } = lazyImport(
  () => import('@/features/customerWallet'),
  'ListCustomerWallet'
);
const { ListTechnicianWallet } = lazyImport(
  () => import('@/features/technicianWallet'),
  'ListTechnicianWallet'
);
const { ListLoyaltyPoint } = lazyImport(
  () => import('@/features/loyaltyPoints'),
  'ListLoyaltyPoint'
);

const { CreateService } = lazyImport(() => import('@/features/services'), 'CreateService');
const { CreateInvoice } = lazyImport(() => import('@/features/invoice'), 'CreateInvoice');

const { EditService } = lazyImport(() => import('@/features/services'), 'EditService');
const { EditInvoice } = lazyImport(() => import('@/features/invoice'), 'EditInvoice');

const { ListOrders } = lazyImport(() => import('@/features/orders'), 'ListOrders');
const { CreateOrder } = lazyImport(() => import('@/features/orders'), 'CreateOrder');
const { CompletedOrders } = lazyImport(() => import('@/features/orders'), 'CompletedOrders');
const { DefferedOrders } = lazyImport(() => import('@/features/orders'), 'DefferedOrders');

const { Technicians } = lazyImport(
  () => import('../features/technician/routes/Technicians'),
  'Technicians'
);
const { PendingTechnicians } = lazyImport(
  () => import('../features/technician/routes/PendingTechnicians'),
  'PendingTechnicians'
);
const { EditTechnician } = lazyImport(
  () => import('../features/technician/routes/EditTechnician'),
  'EditTechnician'
);
const { AddTechnician } = lazyImport(
  () => import('../features/technician/routes/AddTechnician'),
  'AddTechnician'
);

const { Settings } = lazyImport(() => import('../features/settings/Settings'), 'Settings');
const { Limits } = lazyImport(() => import('../features/limits/Limits'), 'Limits');
const { PrivacyPage } = lazyImport(() => import('@/features/accountSettings/components/PrivacyPolicy'),'PrivacyPage');
const { TermsPage } = lazyImport(() => import('@/features/accountSettings/components/TermsAndConditions'),'TermsPage');
const { AboutPage } = lazyImport(() => import('@/features/accountSettings/components/AboutUs'),'AboutPage');
const { Faqs } = lazyImport(() => import('@/features/accountSettings/components/Faqs/Faq'),'Faqs');
const { AddFaq } = lazyImport(() => import('@/features/accountSettings/components/Faqs/AddFaq'),'AddFaq');
const { EditFaq } = lazyImport(() => import('@/features/accountSettings/components/Faqs/EditFaq'),'EditFaq');
const { SiteSettings } = lazyImport(() => import('../features/siteSettings/SiteSettings'), 'SiteSettings');
const { ListMoneyRequests } = lazyImport(() => import('@/features/moneyRequest'), 'ListMoneyRequests');
const { TechnicalTermsPage } = lazyImport(() => import('@/features/accountSettings/components/TechnicalTermsAndConditions'),'TechnicalTermsPage');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: 'profile', element: <Profile /> },
      { path: '', element: <Dashboard /> },
      { path: 'technician/pending', element: <PendingTechnicians /> },
      { path: 'technician/create', element: <AddTechnician /> },
      { path: 'technician/edit/:id', element: <EditTechnician /> },
      { path: 'technicians', element: <Technicians /> },
      { path: 'users', element: <AdminUsers /> },
      { path: 'users/create', element: <CreateAdminUser /> },
      { path: 'users/edit/:id', element: <EditAdminUser /> },
      { path: 'users/permission/:id', element: <PermissionAdminUser /> },
      { path: 'app-users', element: <Users /> },
      { path: 'app-users/create', element: <CreateUser /> },
      { path: 'app-users/edit/:id', element: <EditUser /> },
      { path: 'services', element: <ListServices /> },
      { path: 'services/create', element: <CreateService /> },
      { path: 'service/:serviceid/subservices', element: <ListSubServices /> },
      { path: 'service/:serviceid/subservice/create', element: <AddSubService/> },
      { path: 'service/:serviceid/subservice/edit/:id', element: <EditSubService/> },
      { path: 'services/edit/:id', element: <EditService /> },
      { path: 'orders', element: <ListOrders /> },
      { path: 'orders/create', element: <CreateOrder /> },
      { path: 'orders/completed', element: <CompletedOrders /> },
      { path: 'orders/cancelled', element: <CancelledOrders /> },
      { path: 'orders/processing', element: <InprocessOrders /> },
      { path: 'orders/deferred', element: <DefferedOrders /> },
      { path: 'settings', element: <Settings /> },
      { path: 'invoice', element: <ListInvoice /> },
      { path: 'invoice/create', element: <CreateNewInvoice /> },
      { path: 'invoice/edit/:id', element: <EditInvoice /> },
      { path: 'coupons', element: <ListCoupon /> },
      { path: 'create-coupon', element: <CreateCouponAdmin /> },
      { path: 'coupon/edit/:id', element: <EditCoupon /> },
      { path: 'balance-management', element: <ListBalance /> },
      { path: 'add-balance', element: <Balance /> },
      { path: 'loyalty-points', element: <ListLoyaltyPoint /> },
      { path: 'customer-wallet-report', element: <ListCustomerWallet /> },
      { path: 'technician-wallet-report', element: <ListTechnicianWallet /> },
      { path: 'customer-referral-code', element: <ListCustomerRefCode /> },
      { path: 'financial-report', element: <ListFinancialReport /> },
      { path: 'work-control', element: <ListWorkControl /> },
      { path: 'limits', element: <Limits /> },
      { path: 'site-settings', element: <SiteSettings /> },
      { path: 'privacy-edit', element: <PrivacyPage/>},
      { path: 'terms-edit', element: <TermsPage/>},
      { path: 'technical-terms-edit', element: <TechnicalTermsPage/>},
      { path: 'about-edit', element: <AboutPage/>},
      { path: 'faqs', element: <Faqs/>},
      { path: 'faq/create', element: <AddFaq/>},
      { path: 'faq/edit/:id', element: <EditFaq/>},
      { path: 'money-requests', element: <ListMoneyRequests /> },
      { path: '*', element: <Navigate to="." /> },
    ],
  },
];
