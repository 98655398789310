import { ItemValues } from '@/features/invoice/routes/CreateItem';
import { axios } from '@/lib/axios';
import { Service, User } from '@/types';

export type UpdateCouponDTO = {
    type: string
    name_eng: string
    name_ar: string
    coupon_on: Service
    start_date: string
    end_date:string
    service_name:string
    balance: string
    coupon_code: string
};

export const updateCoupon = (id: string, data: UpdateCouponDTO): Promise<void> => {
  return axios.put(`/admin/update-coupon/${id}`, data);
};
