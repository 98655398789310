import { Dialog, Menu, Transition } from '@headlessui/react';
import { UserIcon, HomeIcon, MenuAlt2Icon, XIcon, ZoomInIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import './sidebar.css';

import logo from '@/assets/logo.svg';
import logo2 from '@/assets/logo2.png';
import { useAuth } from '@/lib/auth';

type SideNavigationItem = {
  name: string;
  to: string;
  icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  children?: SideNavigationItem[];
};

const SideNavigation = ({ permissions }: { permissions: any }) => {
  const dashbeardPermission = permissions.find((permission: any) => permission.name == 'dashboard');
  const techniciansPermission = permissions.find(
    (permission: any) => permission.name == 'technicians'
  );
  const admin_panel_usersPermission = permissions.find(
    (permission: any) => permission.name == 'admin_panel_users'
  );
  const app_usersPermission = permissions.find((permission: any) => permission.name == 'app_users');
  const servicesPermission = permissions.find((permission: any) => permission.name == 'services');
  const all_ordersPermission = permissions.find(
    (permission: any) => permission.name == 'all_orders'
  );
  const completed_ordersPermission = permissions.find(
    (permission: any) => permission.name == 'completed_orders'
  );
  const cancelled_ordersPermission = permissions.find(
    (permission: any) => permission.name == 'completed_orders'
  );
  const inprocess_ordersPermission = permissions.find(
    (permission: any) => permission.name == 'completed_orders'
  );
  const deffered_ordersPermission = permissions.find(
    (permission: any) => permission.name == 'deffered_orders'
  );
  const add_ordersPermission = permissions.find(
    (permission: any) => permission.name == 'add_orders'
  );
  const invoicePermission = permissions.find((permission: any) => permission.name == 'invoice');
  const settingsPermission = permissions.find((permission: any) => permission.name == 'settings');

  const navigation = [
    dashbeardPermission &&
      dashbeardPermission.permissions?.view && { name: 'Dashboard', to: './', icon: HomeIcon },
    techniciansPermission &&
      techniciansPermission.permissions?.view && {
        name: 'Technicians',
        to: './technicians',
        icon: UserIcon,
        // children: [
        //   { name: 'All Technicians', to: './technicians', icon: HomeIcon },
        //   { name: 'Technicians Requests', to: './app-users', icon: HomeIcon },
        // ],
      },
    {
      name: 'Users',
      to: './users',
      icon: UserIcon,
      children: [
        admin_panel_usersPermission &&
          admin_panel_usersPermission.permissions?.view && {
            name: 'Admin Panel Users',
            to: './users',
            icon: HomeIcon,
          },
        app_usersPermission &&
          app_usersPermission.permissions?.view && {
            name: 'App Users',
            to: './app-users',
            icon: HomeIcon,
          },
      ],
    },
    servicesPermission &&
      servicesPermission.permissions?.view && {
        name: 'Services',
        to: './services',
        icon: UserIcon,
      },
    {
      name: 'Orders',
      icon: UserIcon,
      to: './orders',
      children: [
        all_ordersPermission &&
          all_ordersPermission.permissions?.view && {
            name: 'All Orders',
            to: './orders',
            icon: HomeIcon,
          },
        completed_ordersPermission &&
          completed_ordersPermission.permissions?.view && {
            name: 'Completed Orders',
            to: './orders/completed',
            icon: HomeIcon,
          },
        deffered_ordersPermission &&
          deffered_ordersPermission.permissions?.view && {
            name: 'Deferred Orders',
            to: './orders/deferred',
            icon: HomeIcon,
          },
        cancelled_ordersPermission &&
          cancelled_ordersPermission.permissions?.view && {
            name: 'Cancelled Orders',
            to: './orders/cancelled',
            icon: HomeIcon,
          },
        inprocess_ordersPermission &&
          inprocess_ordersPermission.permissions?.view && {
            name: 'Inprocess Orders',
            to: './orders/processing',
            icon: HomeIcon,
          },
      ],
    },

    invoicePermission &&
      invoicePermission.permissions?.view && {
        name: 'Invoice',
        to: './invoice',
        icon: UserIcon,
      },
    {
      name: 'Coupons',
      to: './coupons',
      icon: UserIcon,
    },
    {
      name: 'Balance Management',
      to: './balance-management',
      icon: UserIcon,
    },
    {
      name: 'Reports',
      icon: UserIcon,
      to: './loyalty-points',
      children: [
        { name: 'Loyalty Points', to: './loyalty-points', icon: HomeIcon },
        { name: 'Customer Wallet Report', to: './customer-wallet-report', icon: HomeIcon },
        { name: 'Technician Wallet Report', to: './technician-wallet-report', icon: HomeIcon },
        { name: 'Customer Referral Code', to: './customer-referral-code', icon: HomeIcon },
      ],
    },
    {
      name: 'Work Control',
      to: './work-control',
      icon: UserIcon,
    },
    {
      name: 'Financial Report',
      to: './financial-report',
      icon: UserIcon,
    },
    {
      name:'Money Requests',
      to:'./money-requests',
      icon:ZoomInIcon,
    },
    settingsPermission &&
      settingsPermission.permissions?.view && {
        name: 'Settings',
        to: './settings',
        icon: UserIcon,
      },
      {
        name:'Limits',
        to:'./limits',
        icon:ZoomInIcon,
      },
      {
        name:'Site Settings',
        to:'./site-settings',
        icon:ZoomInIcon,
      },{
        name:'Frontend',
        icon:UserIcon,
        to:'./faqs-edit',
        children: [
          { name: "FAQ's", to: "./faqs", icon: HomeIcon },
          { name: "Privacy Policie's", to: "./privacy-edit", icon: HomeIcon },
          { name: "Terms & Conditions", to: "./terms-edit", icon: HomeIcon },
          { name: "About us", to: "./about-edit", icon: HomeIcon },
          { name: "Technical Term's", to: "./technical-terms-edit", icon: HomeIcon },
        ]
      }
  ].filter(Boolean) as SideNavigationItem[];
  const [active, setActive] = React.useState('');

  return (
    <>
      <ul className="list-unstyled ps-0">
        {navigation.map((item, index) => (
          <li className="mb-1" key={index}>
            <NavLink
              {...(item?.children && {
                'data-bs-toggle': 'collapse',
                'data-bs-target': `#orders-collapse${index}`,
                'aria-expanded': false,
              })}
              to={item.to}
              end={index === 0}
              className={clsx(
                'text-gray-300 gray hover:bg-primary hover:text-white',
                'group flex items-center text-base font-medium rounded-md'
              )}
            >
              <item.icon
                className={clsx(
                  'text-black-400 group-hover:text-black-300',
                  'mr-4 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
              />
              {item.name}
            </NavLink>
            {item?.children && (
              <div className="collapse" id={`orders-collapse${index}`}>
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  {item?.children.map((subItem, index) => (
                    <li key={index}>
                      <Link
                        to={subItem.to}
                        onClick={() => setActive(subItem.to)}
                        className={clsx('link-dark rounded', subItem.to === active ? 'active' : '')}
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

type UserNavigationItem = {
  name: string;
  to: string;
  onClick?: () => void;
};

const UserNavigation = () => {
  const { logout } = useAuth();

  const userNavigation = [
    { name: 'Your Profile', to: './profile' },
    {
      name: 'Sign out',
      to: '',
      onClick: () => {
        logout();
      },
    },
  ].filter(Boolean) as UserNavigationItem[];

  return (
    <Menu as="div" className="ml-3 relative">
      {({ open }) => (
        <>
          <div>
            <Menu.Button className="max-w-xs  bg-orange p-2 flex items-center text-sm rounded-full focus:outline-none text-white">
              <span className="sr-only">Open user menu</span>
              <UserIcon className="h-8 w-8 rounded-full" />
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              static
              className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
              {userNavigation.map((item) => (
                <Menu.Item key={item.name}>
                  {({ active }) => (
                    <Link
                      onClick={item.onClick}
                      to={item.to}
                      className={clsx(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      {item.name}
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

type MobileSidebarProps = {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  permissions: any;
};

const MobileSidebar = ({ sidebarOpen, setSidebarOpen, permissions }: MobileSidebarProps) => {
  return (
    <Transition.Root show={sidebarOpen} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 flex z-40 md:hidden"
        open={sidebarOpen}
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={React.Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </Transition.Child>
        <Transition.Child
          as={React.Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
        >
          <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <Transition.Child
              as={React.Fragment}
              enter="ease-in-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 flex items-center p-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 h-0 overflow-y-auto">
              <nav className="px-2 space-y-1">
                <SideNavigation permissions={permissions} />
              </nav>
            </div>
          </div>
        </Transition.Child>
        <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
      </Dialog>
    </Transition.Root>
  );
};

const Sidebar = ({ permissions }: { permissions: any }) => {
  return (
    <div className="hidden md:flex md:flex-shrink-0">
      <div className="sidebar flex flex-col w-64">
        <div className="flex flex-col h-0 flex-1">
          <div className="flex items-center justify-center h-18 flex-shrink-0 px-4 py-3 bg-white">
            <Logo />
          </div>
          <div className="flex-1 flex flex-col overflow-y-auto">
            <nav className="flex-1 ps-1 pe-3 py-4 pt-1 bg-white space-y-1">
              <SideNavigation permissions={permissions} />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const Logo = () => {
  return (
    <Link className="flex items-center text-white" to=".">
      <img
        className="h-16 w-auto logo-main"
        // src="https://i.ibb.co/fvnvvzg/logo.png"
        src={logo2}
        alt="Workflow"
      />
    </Link>
  );
};
type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const { user } = useAuth();
  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <MobileSidebar
        permissions={user?.resources}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
      <Sidebar permissions={user?.resources} />
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-theme">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 px-4 pt-3 flex justify-end">
            <div className="ml-4 flex user-info-col items-center md:ml-6">
              <UserNavigation />
              <div className="admin-info ml-3">
                <h4 className="f-16 mb-0 bold">Hello, {user?.name}</h4>
                <p className="f-13 mb-0">Admin</p>
              </div>
            </div>
          </div>
        </div>
        <main
          className="flex-1 relative  overflow-y-auto bg-theme focus:outline-none"
          style={{ overflowX: 'hidden' }}
        >
          {children}
        </main>
      </div>
    </div>
  );
};
