import * as React from 'react';

import logo from '@/assets/logo.svg';
import { Link } from '@/components/Elements';
import { Head } from '@/components/Head';
import './login.css';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="login-wrapper mt-8">
          <div className="flex justify-center mb-4">
            <Link className="flex items-center text-white" to="/">
              <img className="h-24 w-auto" src={logo} alt="Workflow" />
            </Link>
          </div>
          <div className="login-content">{children}</div>
        </div>
      </div>
    </>
  );
};
