import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { ContentLayout } from '@/components/Layout/ContentLayout';
import CreateNewInvoiceContainer from '@/container/CreateNewInvoice';
import { useInvoice } from '@/api/invoice/getInvoice';
import { CreateCouponAdmin } from './CreateCoupon';
import { useCouponById } from '@/api/coupon/getCoupon';

export const EditCoupon = () => {
  const { id } = useParams();
  const { data, isLoading, refetch } = useCouponById({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
    
        {/* <ContentLayout heading="Edit Coupon"> */}
          <div>
            <CreateCouponAdmin coupon={data} />
          </div>
        {/* </ContentLayout> */}
    
    </div>
  );
};
