import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

export const getSubService = async (id: string,serviceid:string): Promise<any> => {
    return await axios.get(`/admin/services/${serviceid}/subservices/${id}`);
};

type QueryFnType = typeof getSubService;

type UseSubOptions = {
    config?: QueryConfig<QueryFnType>;
    id: string;
    serviceid:string;
};

export const useSubService = ({ config, id , serviceid}: UseSubOptions = { id: '1',serviceid:'1' }) => {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
        ...config,
        queryKey: [`sub-service-${id}-${serviceid}`],
        queryFn: () => getSubService(id,serviceid),
    });
};