import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Financial, Referrals } from '@/types';

export const getFinancialReport = async (start: string, end: string): Promise<Financial[]> => {
  if (start && end) {
    return axios.get(`/admin/get-order-by-date?start=${start}&end=${end}`);
  } else {
    return axios.get('/admin/financial-report');
  }
};

type QueryFnType = typeof getFinancialReport;

type UseFinancialOptions = {
  config?: QueryConfig<QueryFnType>;
  start: string
  end: string
};

export const useFinancialReport = ({ config, start, end }: UseFinancialOptions = { start: '', end: '' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['financialReport'],
    queryFn: () => getFinancialReport(start, end),
  });
};
