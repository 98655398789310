import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password' | 'number' | 'time' | 'date';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', placeholder, registration, error, value } = props;
  return (
    <FieldWrapper placeholder={placeholder} error={error} value={value}>
      <label htmlFor="" className='d-block'>{placeholder}</label>
      <input
        type={type}
        {...registration}
        placeholder={placeholder}
        value={value}
        className="input-field"
      />
    </FieldWrapper>
  );
};
