import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Coupon, Invoice, User } from '@/types';

export const getCoupon = async (id: string): Promise<Coupon> => {
  return await axios.get(`/admin/get-coupon/${id}`);
};

type QueryFnType = typeof getCoupon;

type UsecouponOptions = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const useCouponById = ({ config, id }: UsecouponOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['coupon'],
    queryFn: () => getCoupon(id),
  });
};
