import { axios } from '../../lib/axios';

import { UserResponse } from '../../features/auth/types';
import { Service } from '@/types';

export type AdminCoupon = {
    type: string
    name_eng: string
    name_ar: string
    coupon_on: Service
    start_date: string
    end_date:string
    service_name:string
    balance: string
    coupon_code: string
};

export const addCoupon = async (data: AdminCoupon): Promise<UserResponse> => {
    return await axios.post('/admin/add-coupon', data);
};
