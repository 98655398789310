import { useQuery } from 'react-query';
import { Setting } from '../../types/setting';
import { axios } from '../../lib/axios';
import { ExtractFnReturnType, QueryConfig } from '../../lib/react-query';

export const getSetting = (): Promise<Setting> => {
  return axios.get(`/admin/get-setting`);
};

type QueryFnType = typeof getSetting;

type UseSettingOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useSetting = ({ config }: UseSettingOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['setting'],
    queryFn: () => getSetting(),
  });
};
