import React, { useEffect, useState } from 'react';

import { ContentLayout } from '@/components/Layout';
import CreateUser from '@/container/CreateUser';
import { ROLES } from '@/lib/authorization';
import { useNavigate } from 'react-router-dom';
import { useTechnician } from '@/api/technicians/getTechnician';
import { Form, InputField, SelectField } from '@/components/Form';
import * as z from 'zod';
import { Setting } from '@/types/setting';
import { Button } from '@/components/Elements';
import { data } from 'msw/lib/types/context';
import { useTechnicians } from '@/api/technicians/getTechnicians';
import { useAppUsers } from '@/api/users/getAppUsers';
import { useServices } from '@/api/services/getServices';
import { addCoupon } from '@/api/coupon/coupon';
import { useNotificationStore } from '@/stores/notifications';
import { Coupon } from '@/types';
import { updateCoupon } from '@/api/coupon/updateCoupon';
import moment from 'moment';

type CreateCoupon = {
  name_eng: string;
  name_ar: string;
  type: string;
  coupon_on: string;
  start_date: string;
  end_date: string;
  balance: string;
  coupon_code: string
};

const schema = z.object({
  name_eng: z.string().trim().min(1, 'Please type Name in English'),

  name_ar: z.string().trim().min(1, 'Please type Name in Arabic'),
  balance: z
    .string()
    .min(1, 'Please enter balance')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only'),

  type: z.string().min(1, 'Please select type'),
  coupon_on: z.string().min(1, 'Please select Coupon On'),
  coupon_code: z.string().min(1, 'Please add coupon code'),
  start_date: z.string().min(1, 'Please add start date'),
  end_date: z.string().min(1, 'Please add end date'),
});

export const CreateCouponAdmin = ({ coupon }: { coupon?: Coupon }) => {
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changeLabel, setChangeLabel] = useState('');
  const { data: services } = useServices();

  const serviceOptions =
    services?.map((entry) => ({
      label: entry.service,
      value: entry._id,
    })) ?? [];

  const couponTypes = [
    {
      label: 'Percentage',
      value: 'percentage',
    },
    {
      label: 'Amount',
      value: 'amount',
    },
  ];
  const couponOntypes = [
    {
      label: 'All Services',
      value: 'all_services',
    },
    {
      label: 'Electricity',
      value: 'electricity',
    },
    {
      label: 'Plumbing',
      value: 'plumbing',
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    if (coupon) {
      setReload(true);
      setTimeout(() => setReload(false), 0);
    }
  }, [coupon]);

  const handleSubmit = async (values: CreateCoupon) => {
    try {
      setLoading(true);
      let msg = '';

      const payload: any = {
        name_eng: values.name_eng,
        name_ar: values.name_ar,
        type: values.type,
        coupon_on: values.coupon_on,
        start_date: moment(values?.start_date).format('YYYY-MM-DD'),
        end_date: moment(values?.end_date).format('YYYY-MM-DD'),
        balance: parseInt(values.balance),
        coupon_code: values.coupon_code
      };

      if (coupon) {
        await updateCoupon(coupon._id, payload);
        msg = 'Coupon updated successfully!';
      } else {
        await addCoupon(payload);
        msg = 'Coupon added successfully!';
      }
      useNotificationStore.getState().addNotification({
        type: 'success',
        title: 'Success',
        message: msg,
      });

      navigate('/app/coupons');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ContentLayout heading="" title={!coupon ? 'Create Coupon' : 'Edit Coupon'}>
      <h2 className="f-20 bold pt-4">{coupon ? 'Edit Coupon' : 'Create Coupon'}</h2>
      <br></br>
      <p className="f-20">Please enter the following details</p>
      <div className="col-4">
        {!reload && (
          <Form<CreateCoupon, typeof schema> onSubmit={handleSubmit} schema={schema}>
            {({ register, formState }) => (
              <>
                <div className="row mt-4">
                  <div className="col-12">
                    <InputField
                      error={formState.errors['name_eng']}
                      registration={register('name_eng', {
                        value: coupon?.name_eng,
                      })}
                      type="text"
                      placeholder="Coupon Name (English)"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-12">
                    <InputField
                      error={formState.errors['name_ar']}
                      registration={register('name_ar', {
                        value: coupon?.name_ar,
                      })}
                      type="text"
                      placeholder="Coupon Name (Arabic)"
                    />
                  </div>
                </div>

                <div className="row mt-12">
                  <div className="col-12">
                    <label className="block text-sm font-medium text-gray-700">Coupon type</label>
                    <SelectField
                      error={formState.errors['type']}
                      registration={register('type', {
                        value: coupon?.type ? coupon?.type : '',
                        onChange: (e) => setChangeLabel(e.target.value),
                      })}
                      options={couponTypes}
                    />
                  </div>
                </div>
                {changeLabel === 'percentage' ? (
                  <div className="row mt-10">
                    <div className="col-18">
                      <InputField
                        error={formState.errors['balance']}
                        registration={register('balance', {
                          value: coupon?.balance ? coupon?.balance?.toString() : '',
                        })}
                        type="text"
                        placeholder="Percentage"
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row mt-10">
                    <div className="col-18">
                      <InputField
                        error={formState.errors['balance']}
                        registration={register('balance', {
                          value: coupon?.balance ? coupon?.balance?.toString() : '',
                        })}
                        type="text"
                        placeholder="Amount"
                      />
                    </div>
                  </div>
                )}
                <div className="row mt-12">
                  <div className="col-12">
                    <label className="block text-sm font-medium text-gray-700">Coupon On</label>
                    <SelectField
                      error={formState.errors['coupon_on']}
                      registration={register('coupon_on', {
                        value: coupon?.coupon_on ? coupon?.coupon_on : '',
                        onChange: (e) => setChangeLabel(e.target.value),
                      })}
                      options={serviceOptions}
                    />
                  </div>
                </div>
                <div className="row mt-10">
                    <div className="col-18">
                      <InputField
                        error={formState.errors['coupon_code']}
                        registration={register('coupon_code', {
                          value: coupon?.coupon_code ? coupon?.coupon_code : '',
                        })}
                        type="text"
                        placeholder="Coupon Code"
                      />
                    </div>
                  </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <InputField
                      error={formState.errors['start_date']}
                      registration={register('start_date', {
                        value: coupon?.start_date
                          ? moment(coupon?.start_date).format('YYYY-MM-DD')
                          : '',
                      })}
                      type="date"
                      placeholder="Start Date"
                    />
                  </div>
                </div>

                <div className="row mt-10">
                  <div className="col-18">
                    <InputField
                      error={formState.errors['end_date']}
                      registration={register('end_date', {
                        value: coupon?.end_date
                          ? moment(coupon?.end_date).format('YYYY-MM-DD')
                          : '',
                      })}
                      type="date"
                      placeholder="End Date"
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-4">
                    <Button isLoading={loading} type="submit" className="">
                      {coupon ? 'Edit' : 'Create'}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </div>
    </ContentLayout>
  );
};
