import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as z from 'zod';
import { subServiceCreate } from '@/api/subServices/subServiceCreate';
import { updateSubService } from '@/api/subServices/updateSubService';
import { Button } from '@/components/Elements';
import { Form, InputField, SelectField } from '@/components/Form';

type SubServiceValues = {
  _id: string;
  name:string;
  name_ar:string;
  price:number;
};

export const CreateSubService = ({ subService }: { subService?: SubServiceValues }) => {
  const [reload, setReload] = useState(false);
  const {serviceid}=useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setReload(true);
    setTimeout(() => setReload(false), 0);
  }, [subService]);

  return (
    <div className="faq-form">
      <div className="row">
        {!reload && (
          <div className="col-12 col-md-8 col-lg-7">
            <div>
              
            </div>
            <Form<SubServiceValues>
              onSubmit={async (values) => {
                const payload = {
                    name:values.name,
                    name_ar:values.name_ar,
                    price:values.price
                  }
                if (subService) {
                  await updateSubService(payload, subService?._id ,serviceid?? '');
                } else {
                  await subServiceCreate({ ...payload },serviceid?? '');
                }
                navigate(`/app/service/${serviceid}/subservices`);
              }}
            >
              {({ register, formState }) => (
                <>
                  <InputField
                    error={formState.errors['name']}
                    registration={register('name', {
                      value: subService?.name?? '',
                    })}
                    type="text"
                    placeholder="Name(English)"
                  />
                  <InputField
                    error={formState.errors['name_ar']}
                    registration={register('name_ar', {
                      value: subService?.name_ar ?? '',
                    })}
                    type="text"
                    placeholder="Name(Arabic)"
                  />
                  <InputField
                    error={formState.errors['price']}
                    registration={register('price', {
                      value: subService?.price?? '',
                    })}
                    type="text"
                    placeholder="Price"
                  />
                  <Button
                    type="submit"
                    style={{ backgroundColor: 'orange', marginTop: '5%', cursor: 'pointer' }}
                  >
                    {subService ? 'Update' : 'Add '}
                  </Button>
                </>
              )}
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};
