import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Service } from '@/types';

export const getServices = async (): Promise<Service[]> => {
  return await axios.get('/admin/service');
};

type QueryFnType = typeof getServices;

type UseServicesOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useServices = ({ config }: UseServicesOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['services'],
    queryFn: () => getServices(),
  });
};
