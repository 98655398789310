import React, { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Button, Table, TableColumn } from '@/components/Elements';
import { Form, InputField, SelectField, TextAreaField } from '@/components/Form';
import { useNotificationStore } from '@/stores/notifications';
import { Invoice } from '@/types';
import { createInvoice } from '@/api/invoice/createInvoice';
import { updateInvoice } from '@/api/invoice/updateInvoice';
import { useTechnicians } from '@/api/technicians/getTechnicians';
import { Controller } from 'react-hook-form';
import { Edit } from '@/container/CreateNewInvoice';

export type ItemValues = {
  item_name: string;
  description: string;
  quantity: string;
  unit_price: string;
  total: string;
  _id: string;
};

const schema = z.object({
  item_name: z.string().trim().min(1, 'Please enter item name'),
  description: z.string().trim().min(1, 'Please enter description'),
  quantity: z
    .string()
    .min(1, 'Please enter quantity')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only'),
  unit_price: z
    .string()
    .min(1, 'Please enter unit_price ')
    .regex(/^[-0-9\+]+$/, 'Please enter numbers only'),
  // total: z.string().min(1, 'Please enter total'),
});

const CreateItem = ({
  values,
  submitItem,
  updateValue,
  type,
  edit,
}: {
  values?: ItemValues;
  submitItem: (values: ItemValues) => void;
  updateValue: (values: ItemValues) => void;
  type: string;
  edit: Edit;
}) => {

  const cancelButtonRef = React.useRef(null);
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit.flag) {
    }
    setReload(true);
    setTimeout(() => setReload(false), 0);
  }, [edit]);

  const checkData = (): boolean => {
    return true;
  };
  const handleSubmit = async (values: ItemValues) => {
    try {
      setLoading(true);

      submitItem(values);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!reload && (
        <Form<ItemValues, typeof schema>
          onSubmit={edit.flag ? updateValue : handleSubmit}
          schema={schema}
          options={{
            defaultValues: edit.flag && edit.item ? edit.item : ({} as unknown as ItemValues),
          }}
        >
          {({ register, reset, formState }) => (
            <div>
              <div>
                <div className="row my-3 align-items-end">
                  <div className="col-2 mt-2">
                    <div className="form-group">
                      <InputField
                        error={formState.errors['item_name']}
                        registration={register('item_name', {
                          value: values?.item_name?.toString() ?? '',
                        })}
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>
                  </div>

                  <div className="col-2 mt-2">
                    <InputField
                      error={formState.errors['description']}
                      registration={register('description', {
                        value: values?.description?.toString() ?? '',
                      })}
                      type="text"
                      className="form-control"
                      placeholder="Description"
                    />
                  </div>
                  <div className="col-2 mt-2">
                    <InputField
                      error={formState.errors['quantity']}
                      registration={register('quantity', {
                        value: values?.quantity?.toString(),
                      })}
                      type="text"
                      className="form-control"
                      placeholder="Quantity"
                    />
                  </div>
                  <div className="col-2 mt-2">
                    <InputField
                      error={formState.errors['unit_price']}
                      registration={register('unit_price', {
                        value: values?.unit_price?.toString(),
                      })}
                      type="text"
                      className="form-control"
                      placeholder="Unit Price"
                    />
                  </div>

                  <div className="col-4 mt-2">
                    {!edit.flag ? (
                      <Button
                        type="submit"
                        className=""
                        onClick={() => setTimeout(() => reset(), 0)}
                      >
                        Submit
                      </Button>
                    ) : (
                      <div className="col-12 table-up-del mt-2">
                        <Button type="submit" className="border-btn" ref={cancelButtonRef}>
                          Update
                        </Button>
                        <br></br>
                        <Button
                          type="submit"
                          className=""
                          ref={cancelButtonRef}
                          onClick={() => setTimeout(() => reset(), 0)}
                        >
                          Cancel
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form>
      )}
    </>
  );
};

export default CreateItem;
