import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

export const getTechnician = async (id: string): Promise<User> => {
  return await axios.get('/admin/technician/' + id);
};

type QueryFnType = typeof getTechnician;

type UseTechnicianOptions = {
  config?: QueryConfig<QueryFnType>;
  id: string;
};

export const useTechnician = ({ config, id }: UseTechnicianOptions = { id: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['technician'],
    queryFn: () => getTechnician(id),
  });
};
