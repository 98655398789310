import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Order } from '@/types';

export const getProcessingOrders = (): Promise<Order[]> => {
  return axios.get(`/admin/view-processing-orders`);
};

type QueryFnType = typeof getProcessingOrders;

type UseOrdersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useProcessingOrders = ({ config }: UseOrdersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['processing-orders'],
    queryFn: () => getProcessingOrders(),
  });
};
