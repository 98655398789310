import { useState } from 'react';
import { deleteTechnician } from '@/api/technicians/deleteTechnician';
import { suspendTechnician } from '@/api/technicians/suspendTechnician';
import { useAppUsers } from '@/api/users/getAppUsers';
import { useOrders } from '@/api/orders/getOrders';
import { Spinner, Button, ConfirmationDialog } from '@/components/Elements';
import { Table, TableActions } from '@/components/Elements/Table/Table';
import { ContentLayout } from '@/components/Layout';
import { ViewTechnician } from '@/features/technician/components/ViewTechnician';
import { useDisclosure } from '@/hooks/useDisclosure';
import { Order } from '@/types';
import { formatDate } from '@/utils/format';
import { useNotificationStore } from '@/stores/notifications';
import { upperFirst } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ViewOrder } from '../routes/ViewOrder';
import { getInvoicePdf } from '@/api/orders/getInvoicePdf';
import BackdropLoader from '@/components/Elements/Loader/Backdroploader';

const Orders = ({ orders }: { orders: Order[] }) => {
  const [order, setOrder] = useState<Order>();
  const [data, setData] = useState(orders);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [printing, setPrinting] = useState<boolean>(false);
  const navigate = useNavigate();
  const { open: viewOpen, close: viewClose, isOpen: isViewOpen } = useDisclosure();
  const { open: deleteOpen, close: deleteClose, isOpen: isDeleteOpen } = useDisclosure();

  const handleView = (entry: Order) => {
    viewOpen();
    setOrder(entry);
  };

  const handleDelete = (entry: Order) => {
    setOrder(entry);
    deleteOpen();
  };
  const handleSearch = (search: any) => {
    if (search.length > 0) {
      const filtered = orders.filter((test) =>
        test?.customer?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setData(filtered);
    } else {
      setData(orders);
    }
  };

  const handlePrint = async (entry: Order) => {
    setPrinting(true);
    const { pdf } = await getInvoicePdf(entry._id);
    setPrinting(false);
    window.open(pdf, '_blank');
  };

  const deleteOrder = async () => {
    await deleteTechnician(order?._id ?? '');
    useNotificationStore.getState().addNotification({
      type: 'success',
      title: 'Success',
      message: 'Order deleted successfully!',
    });
    deleteClose();
    // refetch();
  };

  return (
    <div>
      <BackdropLoader open={printing} />
      {!isLoading && (
        <div className="">
          <div className="top-form-wrap" style={{ marginRight: '0%' }}>
            <div className="top-search-form">
              <input
                className="search-text"
                placeholder="Search..."
                type="text"
                onChange={(event) => {
                  handleSearch(event.target.value);
                }}
              />
              <i className="fa fa-search"></i>
            </div>
          </div>
          <Table<Order>
            actions={[TableActions.VIEW, TableActions.PRINT]}
            data={data ?? []}
            tableName="technicians"
            viewTitle="View Order Details"
            columns={[
              {
                title: 'Customer Name',
                field: 'customer',
                Cell({ entry: { customer } }) {
                  return <span>{upperFirst(customer?.name)}</span>;
                },
              },
              {
                title: 'Order Id',
                field: 'uniqueId',
              },
              {
                title: 'Mobile Number',
                field: 'customer',
                Cell({ entry: { customer } }) {
                  return <span>{customer?.phone}</span>;
                },
              },
              {
                title: 'Address',
                field: 'address',
                Cell({ entry: { address } }) {
                  return <span>{address.substring(0, 10)}</span>;
                },
              },
              {
                title: 'Technician Name',
                field: 'assignedTo',
                Cell({ entry: { assignedTo } }) {
                  return <span>{assignedTo ? upperFirst(assignedTo.name) : 'NA'}</span>;
                },
              },
              {
                title: 'Status',
                field: 'status',
                Cell({ entry: { status } }) {
                  return <span>{upperFirst(status)}</span>;
                },
              },
            ]}
            onViewClicked={handleView}
            onDeleteClicked={handleDelete}
            onPrintClicked={handlePrint}
            handleSearch={handleSearch}
          />
        </div>
      )}
      <ViewOrder
        title="Order Details"
        order={order}
        isOpen={isViewOpen}
        close={viewClose}
        handlePrint={handlePrint}
      />
      <ConfirmationDialog
        isOpen={isDeleteOpen}
        close={deleteClose}
        icon="danger"
        title="Delete Order"
        body="Are you sure you want to delete this order?"
        confirmButton={
          <Button type="button" className="bg-red-600" onClick={deleteOrder}>
            Delete Order
          </Button>
        }
      />
    </div>
  );
};

export default Orders;
