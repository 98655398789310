import React from 'react';

const PermissionTable = ({
  name,
  view,
  create,
  edit,
  deleteChk,
  setView,
  setCreate,
  setEdit,
  setDelete,
}: {
  name: string;
  view: boolean;
  create: boolean;
  edit: boolean;
  deleteChk: boolean;
  setView: any;
  setCreate: any;
  setEdit: any;
  setDelete: any;
}) => {
  return (
    <div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5,2fr)' }} className="form-check">
        <div>
          <h6 className="mt-0">{name}</h6>
        </div>
        <div>
          <label className="form-check-label" htmlFor={`${name}View`}>
            View
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value={''}
            checked={view}
            onChange={() => setView(!view)}
            id={`${name}View`}
          />
        </div>
        <div>
          <label className="form-check-label" htmlFor={`${name}Create`}>
            Create
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value={''}
            checked={create}
            onChange={() => setCreate(!create)}
            id={`${name}Create`}
          />
        </div>
        <div>
          <label className="form-check-label" htmlFor={`${name}Edit`}>
            Edit
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value={''}
            checked={edit}
            onChange={() => setEdit(!edit)}
            id={`${name}Edit`}
          />
        </div>
        <div>
          <label className="form-check-label" htmlFor={`${name}Delete`}>
            Delete
          </label>
          <input
            className="form-check-input"
            type="checkbox"
            value={''}
            checked={deleteChk}
            onChange={() => setDelete(!deleteChk)}
            id={`${name}Delete`}
          />
        </div>
      </div>
    </div>
  );
};

export default PermissionTable;
