import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useFaq } from '@/api/faqs/getFaq';
import { ContentLayout } from '@/components/Layout/ContentLayout';
import { CreateSubService } from './CreateSubService';
import { useSubService } from '@/api/subServices/getSubService';

export const EditSubService = () => {
  const { id ,serviceid} = useParams();
  const { data, isLoading, refetch } = useSubService({ id: id ?? '1' ,serviceid:serviceid??'1'});
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {isLoading ? (
        <p>Loading</p>
      ) : (
        <ContentLayout heading="Edit Sub-Service">
          <div className="mt-1">
            <CreateSubService subService={data} />
          </div>
        </ContentLayout>
      )}
    </div>
  );
};