import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Order } from '@/types';

export const getCancelledOrders = (): Promise<Order[]> => {
  return axios.get(`/admin/view-cancelled-orders`);
};

type QueryFnType = typeof getCancelledOrders;

type UseOrdersOptions = {
  config?: QueryConfig<QueryFnType>;
};

export const useCancelledOrders = ({ config }: UseOrdersOptions = {}) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['cancelled-orders'],
    queryFn: () => getCancelledOrders(),
  });
};
