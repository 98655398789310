import Skeleton from '@mui/material/Skeleton';
import React from 'react';

const ListSkeleton = () => {
  return (
    <div>
      <br />
      <br />
      <div className="flex justify-between">
        <div>
          <Skeleton variant="rounded" width={85} height={25} />
          <br />
          <Skeleton variant="rounded" width={205} height={18} />
        </div>
        <div className="flex gap-4">
          <Skeleton variant="rounded" width={234} height={50} />
          <Skeleton variant="rounded" width={199} height={50} />
        </div>
      </div>
      <br />
      <br />
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rounded" height={50} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rounded" height={50} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rounded" height={50} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rounded" height={50} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rounded" height={50} />
        </div>
      </div>
      <div className="row">
        <div className="col-12 mb-4">
          <Skeleton variant="rounded" height={50} />
        </div>
      </div>
    </div>
  );
};

export default ListSkeleton;
