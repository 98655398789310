import { ItemValues } from '@/features/invoice/routes/CreateItem';
import { axios } from '@/lib/axios';
import { User } from '@/types';

export type UpdateInvoiceDTO = {
  customer_address: string;
  customer_name: string;
  customer_vat_no: number;
  user: string;
  item: ItemValues[],
  totalPrice: number;
  final_price: number;
  vat_amount: number;
  invoiceNumber: string;
};


export const updateInvoice = (id: string, data: UpdateInvoiceDTO): Promise<void> => {
  return axios.put(`/admin/invoice/update/${id}`, data);
};
