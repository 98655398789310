import { axios } from '../../lib/axios';

import { UserResponse } from '../../features/auth/types';

export type AdminBalance = {
    type: string
    userType: string
    userId: string
    amount: number
    reason: string
};

export const addBalance = async (data: AdminBalance): Promise<UserResponse> => {
    return await axios.post('/admin/add-balance', data);
};
