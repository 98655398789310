import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '@/types';

export type FinancialPdfResponse = {
  pdf: string;
};

export const getFinancialReportPdf = async (id: string): Promise<FinancialPdfResponse> => {
  return axios.get(`/admin/financial-report/pdfurl/${id}`);
};
 
type QueryFnType = typeof getFinancialReportPdf;

type UseFinancialOptions = {
  config?: QueryConfig<QueryFnType>;
  orderId: string;
};

export const useFinancialReportPdf = ({ config, orderId }: UseFinancialOptions = { orderId: '1' }) => {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: ['financialReportPdf'],
    queryFn: () => getFinancialReportPdf(orderId),
  });
};
