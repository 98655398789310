import React, { useEffect } from 'react';

import { ContentLayout } from '@/components/Layout';
import CreateUser from '@/container/CreateUser';
import { ROLES } from '@/lib/authorization';
import { useParams } from 'react-router-dom';
import { useTechnician } from '@/api/technicians/getTechnician';
import PermissionUser from '@/container/PermissionUser';
import { Spinner } from '@/components/Elements';

export const PermissionAdminUser = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching, refetch } = useTechnician({ id: id ?? '1' });

  useEffect(() => {
    refetch();
  }, [id]);

  if (isFetching) {
    return (
      <div className="w-full h-48 flex justify-center items-center">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <ContentLayout heading="Permissions" title="Permissions">
      {!isFetching && <PermissionUser role={ROLES.ADMIN} user={data} />}
    </ContentLayout>
  );
};
