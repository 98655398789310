import { ContentLayout } from '@/components/Layout/ContentLayout';
import { CreateSubService } from './CreateSubService';

export const AddSubService = () => {
  return (
    <ContentLayout heading="Add Sub-Service">
      <div className="mt-1">
        <CreateSubService />
      </div>
    </ContentLayout>
  );
};