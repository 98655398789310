import Orders from '../components/Orders';

import { useOrders } from '@/api/orders/getOrders';
import { Spinner } from '@/components/Elements';
import { ContentLayout } from '@/components/Layout';
import { useCompletedOrders } from '@/api/orders/getCompletedOrders';
import ListSkeleton from '@/components/Elements/Loader/ListSkeleton';
import { useCancelledOrders } from '@/api/orders/getCancelledOrders';

export const CancelledOrders = () => {
  const { data, isLoading } = useCancelledOrders();

  if (isLoading) {
    return (
      <ContentLayout title="Cancelled Orders">
        <ListSkeleton />;
      </ContentLayout>
    );
  }

  return (
    <ContentLayout title="Cancelled Orders">
      <div
        style={{
          height: '80px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <h2 className="f-20 bold">Cancelled Orders</h2>
        </div>
      
      </div>
      <Orders orders={data ?? []} />
    </ContentLayout>
  );
};
